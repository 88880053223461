import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getRolesList, getSupervisorList, getTeamsList } from "../../../../application/selector/indexSelector";
import {validateRequired, validateRequiredDropdown, validateRequiredNumberDropdown, restrictSpace, restrictConsecutiveSpace} from "../../../../infrastructure/core/validationUtils";
import Environment from "../../../../infrastructure/core/Environment";
import { AddUser } from "../../../../application/action/userAction";
import useAuth from "../../../../hooks/useAuth";

export default function CreateNewUser(props) {
  const RolesList = useSelector(getRolesList);
  const TeamsList = useSelector(getTeamsList);
  const SupervisorList = useSelector(getSupervisorList);
  const dispatch = useDispatch()
  const { setAuth, auth } = useAuth();

  const initialState = {
    fullName: "",
    username: "",
    roleId: 0,
    email: "",
    password: "Abteam@2024",
    empCode: "",
    supervisorId: Environment.defaultValue,
    teamId: Environment.defaultValue,
    createdBy: auth.id,
    modifiedBy: auth.id,
  };
  const [formData, setFormData] = useState(initialState);

  const [errors, setErrors] = useState({});
  const validateInput = () => {
    const newErrors = {};
    // Validate Charge Name
    newErrors.fullName = validateRequired(formData.fullName)
      ? ""
      : "Full Name is required";
    newErrors.email = validateRequired(formData.email)
      ? ""
      : "Email is required";
    newErrors.username = validateRequired(formData.username)
      ? ""
      : "Username is required";
    newErrors.empCode = validateRequired(formData.empCode)
      ? ""
      : "Emp Code is required";
    newErrors.roleId = validateRequiredNumberDropdown(formData.roleId)
      ? ""
      : "Role is required";
    newErrors.teamId = validateRequiredDropdown(formData.teamId)
      ? ""
      : "Team is required";
    newErrors.supervisorId = validateRequiredDropdown(formData.supervisorId)
      ? ""
      : "Supervisor is required";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value?.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
      // Email format validation
      // if (name === "email") {
      //   const isValidEmail = validateEmail(value);
      //   if (!isValidEmail) {
      //     setErrors({
      //       ...errors,
      //       [name]: "Invalid email format",
      //     });
      //     return;
      //   } else {
      //     checkDuplicateEmail(name, value);
      //   }
      // }
    } else {
      setErrors({
        ...errors,
        [name]: `${
          name === "fullName"
            ? "Full Name"
            : name === "email"
            ? "Email"
            : name === "username"
            ? "Username"
            : name === "empCode"
            ? "Emp Code"
            : name === "roleId"
            ? "Role"
            : name === "teamId"
            ? "Team"
            : name === "supervisorId"
            ? "Supervisor"
            : "This Field"
        } is required`,
      });
    }
  };;

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    // const isValid = validateInput();
    // if (!isValid) {
    //   return;
    // }
    dispatch(AddUser([formData]))
    setFormData(initialState)
    props.closeFunction()
  };

  return (
    <Offcanvas
      show={props.show}
      onHide={props.closeFunction}
      placement="end"
      className="w-40"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="fs-16 text-dark">
          Create New User
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>Name<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                onKeyDown={restrictConsecutiveSpace}
              />
              <Form.Text className="text-danger">
                  {errors.fullName}
                </Form.Text>
            </div>
          </Col>
          <Col>
            <div className="mb-4">
              <Form.Label>Email<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  restrictSpace(e);
                }}
              />
              <Form.Text className="text-danger">
                  {errors.email}
                </Form.Text>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label> Username<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  restrictSpace(e);
                }}
              />
              <Form.Text className="text-danger">
                  {errors.username}
                </Form.Text>
            </div>
          </Col>
          <Col>
            <div className="mb-4">
              <Form.Label>EMP Code<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter emp Code"
                name="empCode"
                value={formData.empCode}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  restrictSpace(e);
                }}
              />
              <Form.Text className="text-danger">
                  {errors.empCode}
                </Form.Text>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>Role<span className="text-danger">*</span></Form.Label>
              <Form.Select
                name="roleId"
                value={formData.roleId}
                onChange={handleInputChange}
              >
                <option disabled value='0'>Choose Role</option>
                {RolesList?.map((role) => (
                  <option key={role.id} value={Number(role.id)}>
                    {role.role}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-danger">
                  {errors.roleId}
                </Form.Text>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-4">
              <Form.Label>Team Name<span className="text-danger">*</span></Form.Label>
              <Form.Select
                name="teamId"
                value={formData.teamId}
                onChange={handleInputChange}
              >
                <option disabled value={Environment.defaultValue}>Choose Team Name</option>
                {TeamsList?.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.teamName}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-danger">
                  {errors.teamId}
                </Form.Text>
            </div>
          </Col>
        </Row>

        <Row>
          {formData.roleId == 6 && (
            <Col>
              <div className="mb-4">
                <Form.Label>Supervisor<span className="text-danger">*</span></Form.Label>
                <Form.Select
                  name="supervisorId"
                  value={formData.supervisorId}
                  onChange={handleInputChange}
                >
                  <option disabled value={Environment.defaultValue}>Choose Supervisor</option>
                  {SupervisorList?.map((sup) => (
                    <option key={sup.empId} value={sup.empId}>
                      {sup.emp_fullname}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.supervisorId}
                </Form.Text>
              </div>
            </Col>
          )}
          
        </Row>
       
      </Offcanvas.Body>

      <div className="offcanvas-footer justify-content-start">
        <Button
          type="submit"
          variant="primary"
          className="fs-14 me-2 d-flex align-items-center"
          onClick={handleSubmit}
        >
          <i className="ri-add-line fs-18 lh-1 align-middle"></i>
          <span className="align-middle">Create User</span>
        </Button>
      </div>
    </Offcanvas>
  );
}

export const getLoading = (state) => state.loader;

//Teams
export const getTeamsList = (state) => state.teams.TeamsList
// User
export const getUsersMappingList = (state) => state.user.UsersMappingList
export const getUserGroupList = (state) => state.user.UserGroupList
export const getSupervisorList = (state) => state.user.SupervisorList
export const getAuditorList = (state) => state.user.AuditorList
export const getRolesList = (state) => state.user.RolesList
export const getUserList = (state) => state.user.UserList
export const getUserDetail = (state) => state.user.UserDetail

// Errors
export const getErrorList = (state) => state.error.ErrorList
export const getErrorCategoryList = (state) => state.error.ErrorCategoryList
export const getSubErrorList = (state) => state.error.SubErrorList
export const getPointList = (state) => state.error.PointList

export const getPendingResponse = (state) => state.pendingResponse.PendingResponseList

//Inventory
export const getInventoryList = (state) => state.inventory.InventoryList
export const getDataSetList = (state) => state.inventory.DataSetList
export const getDataSourceList = (state) => state.inventory.DataSourceList
export const getInventoryDataList = (state) => state.inventory.InventoryDataList
export const getHeaderList = (state) => state.inventory.HeaderList

//project

export const getProjectList = (state) => state.project.ProjectList
export const getProgressData = (state) => state.loader.progress;
export const getOperationId = (state) => state.loader.operationId
export const getProjectDetails = (state) => state.project.ProjectDetails
export const getMapProjectAuditorList = (state) => state.project.MapProjectAuditorList
export const getProjectAuditFormListById = (state) => state.project.ProjectAuditFormListById
export const getMapProjectAuditFormList = (state) => state.project.MapProjectAuditFormList
export const getMapProjectColumnList = (state) => state.project.MapProjectColumnList
export const getAuditFormListByProjId = (state) => state.project.AuditFormListByProjId


// audit
export const getAuditFormList = (state) => state.project.AuditFormList
export const getMapAuditFormErrorsList = (state) => state.project.MapAuditFormErrorsList
export const getMapAuditFormColumnList = (state) => state.project.MapAuditFormColumnList
export const getOpenCasesList = (state) => state.audit.OpenCasesList
export const getClosedCasesList = (state) => state.audit.ClosedCasesList
export const getFinalClosedCasesList = (state) => state.audit.FinalClosedCasesList
export const getPendingResponseCasesList = (state) => state.audit.PendingResponseCasesList
export const getOngoingCasesList = (state) => state.audit.OngoingCasesList
export const getPendingCasesList = (state) => state.audit.PendingCasesList
export const getAuditDetails = (state) => state.audit.AuditDetails
export const getMapProjectList = (state) => state.project.MapProjectList
export const getAuditErrors = (state) => state.audit.AuditErrors
export const getChangeLog = (state) => state.audit.ChangeLog
export const getAuditId = (state) => state.audit.AuditId
export const getCaseId = (state) => state.audit.CaseId
export const getCaseFilesList = (state) => state.audit.CaseFilesList

import React, { useState } from "react";
import { Button, Card, Table, Row, Col, Badge } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getErrorCategoryList } from "../../../application/selector/indexSelector";
import AuditSubError from "./audit-sub-error";
import Environment from "../../../infrastructure/core/Environment";

export default function AuditError(props) { // Fixed component name (AditError -> AuditError)
    const ErrorCategoryList = useSelector(getErrorCategoryList);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [addedCategories, setAddedCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [showAuditSubError, setShowAuditSubError] = useState(false);
console.log(ErrorCategoryList);

    // Generate category options with proper filtering
    const errorCategoryOptions = (ErrorCategoryList?.map((category) => ({
        value: category.id,
        label: category.errorCategoryName || 'Unknown Category',
    })) || []).filter(
        (category) => !addedCategories.some(added => added.value === category.value )
    );

    const handleCategoryChange = (selectedOptions) => {
        setSelectedCategories(selectedOptions || []);
    };

    const handleInclude = () => {
        if (selectedCategories.length > 0) {
            setAddedCategories(prev => [
                ...prev,
                ...selectedCategories.map(cat => ({
                    ...cat,
                    subErrorCount: 0 // Initialize sub-error count
                }))
            ]);
            setSelectedCategories([]);
        }
    };

    const handleDelete = (categoryId) => {
        setAddedCategories(prev => prev.filter(category => category.value !== categoryId));
    };

    const handleSubErrorAdded = (categoryId) => {
        setAddedCategories(prev => prev.map(cat => 
            cat.value === categoryId 
                ? { ...cat, subErrorCount: cat.subErrorCount + 1 } 
                : cat
        ));
    };

    return (
        <React.Fragment>
            <AuditSubError
                show={showAuditSubError}
                closeFunction={() => setShowAuditSubError(false)}
                data={props?.data}
                selectedCategory={selectedCategory}
                onSuccess={handleSubErrorAdded}
            />

            <div className="mb-4">
                <Row className="align-items-center">
                    <Col md={5}>
                        <Select
                            options={errorCategoryOptions}
                            placeholder="Select Error Category"
                            isMulti
                            isSearchable
                            value={selectedCategories}
                            onChange={handleCategoryChange}
                        />
                    </Col>
                    <Col md={4} className="mt-md-0 mt-2">
                        <Button 
                            variant="dark" 
                            onClick={handleInclude}
                            disabled={selectedCategories.length === 0}
                        >
                            Add Categories ({selectedCategories.length})
                        </Button>
                    </Col>
                </Row>
            </div>

            {addedCategories.length > 0 && (
                <Card className="card-one">
                    <Card.Body className="p-0">
                        <Table className="w-100 mb-0" responsive>
                            <thead className="bg-light">
                                <tr>
                                    <th>Category Name</th>
                                    <th>Sub Errors</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {addedCategories.map((category) => (
                                    <tr key={category.value}>
                                        <td>{category.label}</td>
                                        <td>
                                            <Badge pill bg="primary">
                                                {category.subErrorCount}
                                            </Badge>
                                        </td>
                                        <td width="wt-100">
                                            <div className="d-flex gap-2">
                                                <Button className="btn-icon"
                                                    variant="outline-dark"
                                                    onClick={() => {
                                                        setSelectedCategory(category.value);
                                                        setShowAuditSubError(true);
                                                    }}
                                                >
                                                    <i className="ri-eye-line" />
                                                </Button>
                                                <Button className="btn-icon"
                                                    variant="danger"
                                                    onClick={() => handleDelete(category.value)}
                                                >
                                                    <i className="ri-delete-bin-line" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                    
                </Card>
            )}
        </React.Fragment>
    )
}
import { format } from 'date-fns';
import React, { useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import ToastifyService from '../../../_common/ToastifyService';
import SweetAlertService from '../../../_common/SweetAlertService';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../../../hooks/useAuth';
import { getSubErrorList } from '../../../../application/selector/indexSelector';
import { DeleteErrorSubCategory, SubErrorUpload } from '../../../../application/action/errorAction';
import DataTable from 'react-data-table-component';
import SubErrorCategory_Template from '../../../../assets/templates/SubErrorCategory_Template.xlsx'

function SubError() {
    const [filesInfo, setFilesInfo] = useState([]);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const validFileExtensions = ['.xls', '.xlsx', '.csv'];
    const fileInputRef = useRef(null);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [editRowData, setEditRowData] = useState({});
    const dispatch = useDispatch();
    const { setAuth, auth } = useAuth();
    const SubErrorsList = useSelector(getSubErrorList)
    const handleFileUpload = (event) => {
        const files = event.target.files;
        if (files.length === 0) return; // No file selected

        const formData = new FormData();
        formData.append("CreatedBy", auth.id);
        formData.append("File", files[0]); // Use the first file

        dispatch(SubErrorUpload(formData)); // Assuming `dispatch` is correctly set up

        setError(null);
        setFilesInfo([]);
    };



    const handleBulkUpload = () => {
        fileInputRef.current.click();
    };

    const handleEdit = (row, index) => {
        setEditRowData({ ...row });
        setShowUpdateUser(true);
    };

    const handleSaveEdit = (updatedData) => {
        const newData = [...data];
        newData[editRowData.index] = updatedData;
        setData(newData);
    };

    // const handleDelete = (id) => {
    //     SweetAlertService.showAlert(
    //         "Error Sub Category",
    //         "Are you sure you want to delete this Error Sub Category?",
    //         "warning"
    //     )
    //         .then((result) => {
    //             if (result.isConfirmed) {
    //                 dispatch(DeleteErrorSubCategory(id))
    //                 ToastifyService.success(`Error Sub Category Deleted Successfully`);

    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error:", error);
    //             ToastifyService.error(`Something went wrong`);
    //         });
    // };
    const columns = [
        {
            name: "Error Type",
            selector: (row) => (
                <div>
                    {row.subErrorName}

                </div>
            ),
        },
        {
            name: "Modified On",
            selector: (row) => format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    {/* <Button
                        variant="primary"
                        className="btn-icon me-2"
                        onClick={() => handleEdit(true, row)}
                    >
                        <i className="ri-pencil-line"></i>
                    </Button> */}
                    {/* <Button
                        variant="outline-danger"
                        className="btn-icon"
                        onClick={() => handleDelete(row.id)}
                    >
                        <i className="ri-delete-bin-line"></i>
                    </Button> */}
                    <Form.Check
                        type="switch"
                        id={`custom-switch-${row.id}`}
                        checked={row.status === '5e03965f-c410-46b3-8713-b5058348ab02'} // Check if status matches
                        className="mb-0"
                        onChange={(e) => handleStatusChange(row.id, e.target.checked)} // Handle status change
                        custom
                    />
                </div>
            ),
            width: "200px",
        },
    ];
    const handleStatusChange = (id, isChecked) => {
        const newStatus = isChecked ? '5e03965f-c410-46b3-8713-b5058348ab02' : '5e03965f-c410-46b3-8713-b5058348ab03';

        // Update the status in the backend
        dispatch(DeleteErrorSubCategory({ id, status: newStatus }))
            .then(() => {
                // Update the status in the local state
                const updatedData = data.map((item) =>
                    item.id === id ? { ...item, status: newStatus } : item
                );
                setData(updatedData);

            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Failed to update status`);
            });
    };
    const [searchTerm, setSearchTerm] = useState('');
    const filteredSubErrorsList = SubErrorsList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    {/* <h4 className="fs-16"> Sub Error Type</h4> */}

                </div>
                <div className="d-flex gap-2">
                    <div className="custom-drop-down z-index-2 wt-300">
                        {/* <span className=" d-flex dropdown-icon bg-white"><i className="ri-search-line"></i></span> */}
                        <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    {/* <div>
                        <a
                            href={SubErrorCategory_Template}
                            download="ErrorType_Template.xlsx"
                        >
                            <Button variant="outline-primary">
                                {" "}
                                <i className="ri-download-line fs-16 lh-1"></i> Download
                                Template
                            </Button>
                        </a>

                    </div> */}
                    <div>
                        <Form.Control
                            type="file"
                            ref={fileInputRef} style={{ display: "none" }} accept=".xls,.xlsx,.csv" onChange={handleFileUpload}
                        />
                        <Button variant="primary" onClick={handleBulkUpload}>
                            <i className="ri-file-excel-2-line align-middle"></i> Upload Sub Error Type
                        </Button>
                    </div>

                </div>
            </div>

            <div>
                {error && <p className="text-center" style={{ color: "red" }}>{error}</p>}
                {filteredSubErrorsList?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={filteredSubErrorsList}
                        pagination
                        highlightOnHover
                    />
                ) : (
                    !error && <p className="text-center">No records to display</p>
                )}
            </div>
        </React.Fragment>
    )
}

export default SubError
import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Row, Col, Table, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getErrorList, getPointList } from "../../../application/selector/indexSelector";
import Environment from "../../../infrastructure/core/Environment";
import { MapAuditFormErrorType } from "../../../application/action/projectAction";
import useAuth from "../../../hooks/useAuth";

export default function AuditErrorType(props) {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const ErrorList = useSelector(getErrorList);
    const PointList = useSelector(getPointList);

    const [selectedErrors, setSelectedErrors] = useState([]);
    const [addedErrors, setAddedErrors] = useState([]);

    const initialState = {
        mappingId: props?.mappingId || Environment.defaultValue,
        errorId: Environment.defaultValue,
        errorCategoryId: props?.selectedCategory || Environment.defaultValue,
        subErrorCategoryId: props?.selectedSubCategory || Environment.defaultValue,
        formId: props?.data?.formId || Environment.defaultValue,
        points: 0,
        createdBy: auth.id,
        modifiedBy: auth.id
    };

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            mappingId: props?.mappingId || Environment.defaultValue,
            errorCategoryId: props?.selectedCategory,
            subErrorCategoryId: props?.selectedSubCategory,
            formId: props?.data?.formId,
        }));
    }, [props]);

    // Filter errors to exclude already added ones
    const selectErrorOptions = (ErrorList?.map(data => ({
        value: data.id,
        label: data.errorName
    })) || []).filter(
        error => !addedErrors.some(added => added.value === error.value)
    );

    const pointOptions = PointList?.map(data => ({
        value: data.id,
        label: data.pointValue
    })) || [];

    const handleErrorChange = (selectedOptions) => {
        setSelectedErrors(selectedOptions || []);
    };

    const handleInclude = () => {
        if (selectedErrors.length > 0) {
            const newErrors = selectedErrors.map(error => ({
                ...error,
                points: '',
                pointId: null
            }));

            setAddedErrors(prev => [...prev, ...newErrors]);
            setSelectedErrors([]);
        }
    };

    const handleDelete = (value) => {
        setAddedErrors(prev => prev.filter(error => error.value !== value));
    };

    const handlePointChange = (errorValue, newPoint) => {
        setAddedErrors(prev =>
            prev.map(error =>
                error.value === errorValue ? {
                    ...error,
                    points: newPoint?.label || '',
                    pointId: newPoint?.value || null
                } : error
            )
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = addedErrors.map(error => ({
            ...formData,
            errorId: error.value,
            points: error.points,
            pointId: error.pointId
        }));

        dispatch(MapAuditFormErrorType(payload));
        setAddedErrors([]);
        props.closeFunction();
    };

    // Check if all errors have points selected
    const allPointsSelected = addedErrors.every(error => error.pointId !== null);

    return (
        <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-50">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-16 text-dark">Error Type</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Row className="g-3">
                    <Col md={8}>
                        <Select
                            options={selectErrorOptions}
                            isSearchable
                            isMulti
                            value={selectedErrors}
                            onChange={handleErrorChange}
                            placeholder="Select Error Types"
                        />
                    </Col>

                    <Col md={4}>
                        <div className="d-flex gap-2">
                            <Button
                                variant="dark"
                                onClick={handleInclude}
                                disabled={!selectedErrors.length}
                            >
                                Include
                            </Button>
                        </div>
                    </Col>

                    {addedErrors.length > 0 && (
                        <Col md={12}>
                            <Card className="card-one h-auto mt-3">
                                <Card.Body>
                                    <Table className="w-100 mb-0">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Error Name</th>
                                                <th>Points</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {addedErrors.map((error) => (
                                                <tr key={error.value}>
                                                    <td>{error.label}</td>
                                                    <td>
                                                        <Select
                                                            options={pointOptions}
                                                            value={pointOptions.find(opt => opt.value === error.pointId)}
                                                            onChange={(selected) =>
                                                                handlePointChange(error.value, selected)
                                                            }
                                                            isClearable={false}
                                                            placeholder="Select Points"
                                                            className="wt-200"
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className="btn-icon"
                                                            variant="outline-danger"
                                                            onClick={() => handleDelete(error.value)}
                                                        >
                                                            <i className="ri-delete-bin-line" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Offcanvas.Body>

            <div className="offcanvas-footer justify-content-start justify-content-end">
                <Button variant="primary" onClick={handleSubmit} disabled={!addedErrors.length || !allPointsSelected}>Save Changes</Button>
            </div>
        </Offcanvas>
    );
};
import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Nav,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import ToastifyService from "../_common/ToastifyService";
import LoadingSpinner from "../_common/LoadingSpinner";
import logo from "../../assets/img/logo/main-logo.png";
import useAxiosPrivate from "./../../hooks/useAxiosPrivate";
import Environment from "../../infrastructure/core/Environment";
const passwordRegex =
  /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&]).{8,20})/;

function ResetPassword() {
  document.body.classList.remove("sidebar-show");
  const { setAuth, auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState("");

  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });

  const [formData, setFormData] = useState({
    email:auth.email,
    oldPass: auth.pwd,
    newPass: "",
  });
  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!validatePassword(formData.newPass)) {
      setPasswordError(
        "Password must be at least 8 characters long, contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
      );
      return;
    }
    if (formData.newPass !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    setLoading({ loading: true, value: "handleSetUpPassword" });
    setPasswordError("");
    const data ={
      email:auth.email,
      oldPass: auth.pwd,
      newPass: formData.newPass,
    }
    try {
      setLoading({
        isLoading: true,
        value: "resetPassword",
      });
      const response = await axiosPrivate.post(
        Environment.USER_URL + "reset-password",
        data  
      );
      ToastifyService.success("Password Reset Successful");
      const accessToken = response?.data?.accessToken;
      const refreshToken = response?.data?.refreshToken;
      const roles = response?.data?.roles;
      const id = response?.data?.id;
      const isResetDefaultPass = response?.data?.isResetDefaultPass;
      const name = response?.data?.name;
      const userName = formData.username;
      const password = formData.newPass;
      setAuth({
        userName,
        password,
        roles,
        accessToken,
        refreshToken,
        id,
        isResetDefaultPass,
        name,
      });
      navigate("/dashboard/manage");
    } catch (error) {
ToastifyService.error("Something Went wrong! ");
      console.error(error);
      ToastifyService.error("Oops! Something Went Wrong");
      setLoading({
        isLoading: false,
        value: "resetPassword",
      });
    } finally {
      navigate("/dashboard/manage");
      setLoading({
        isLoading: false,
        value: "resetPassword",
      });
    }
  };
  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          {/* <Link to="/account/login" className="header-logo">
            Natera
          </Link> */}
          <Link to="/" className="sidebar-logo">
            <Image src={logo} className="wt-150" />
          </Link>
          <Nav className="nav-icon">
            <Nav.Link href="">
              <i className="ri-twitter-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-github-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-dribbble-line"></i>
            </Nav.Link>
          </Nav>
        </Container>

        <div className="content">
          <Container>
            <Row className="g-2">
              <Col md="7" lg="4" className="col-wrapper">
                <Card className="card-sign">
                  <Card.Header>
                    <Card.Title>Reset your Password</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <div className="mb-4">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter your Username"
                          name="password"
                          value={formData.newPass}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              newPass: e.target.value,
                            });
                          }}
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <Form.Label className="d-flex justify-content-between">
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          placeholder="Enter your password"
                          type="password"
                          required
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              confirmPassword: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {passwordError && (
                    <Alert variant="danger" className="mt-2">
                      {passwordError}
                    </Alert>
                  )}
                      <Button
                        type="button"
                        className="btn-sign"
                        disabled={
                          loading.isLoading &&
                          loading?.value === "resetPassword"
                        }
                        onClick={handleResetPassword}
                      >
                        {loading.isLoading &&
                        loading?.value === "resetPassword" ? (
                          <LoadingSpinner
                            color="#ffffff"
                            size={30}
                            type={"TailSpin"}
                          />
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </Form>
                  </Card.Body>
                  {/* <Card.Footer>
                    {" "}
                    Don't have an account?{" "}
                    <Link to="/account/signup">Create an Account</Link>
                  </Card.Footer> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

import React, { useRef, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import excelIcon from "../../../../assets/img/icons/excel.png"
import pdfIcon from "../../../../assets/img/icons/pdf.png"
import screenshortIcon from "../../../../assets/img/icons/screenshort.png";
import { useParams } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { getAuditDetails, getCaseFilesList } from "../../../../application/selector/indexSelector";
import { AddChangeLog, UploadCaseDocument } from "../../../../application/action/auditAction";
import axios from "axios";
import Environment from "../../../../infrastructure/core/Environment";
import ToastifyService from "../../../_common/ToastifyService";
import DocumentUpload from "./document-upload";

export default function UploadedDocument() {
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const { auditId } = useParams();
    const { auth } = useAuth()
    const dispatch = useDispatch()
    const AuditDetailData = useSelector(getAuditDetails)
    const CaseFilesList = useSelector(getCaseFilesList)
    const [showUploadDocModal, setShowUploadDocModal] = useState(false);

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files); // Convert FileList to Array
        const formData = new FormData();

        // Append each file to the FormData object
        selectedFiles.forEach((file) => {
            formData.append("Files", file); // 'Files' must match the backend parameter name
            formData.append("FileNames", file.name);
        });

        // Append additional fields to FormData
        formData.append("auditId", auditId);
        formData.append("caseId", AuditDetailData.caseId);
        formData.append("lmsCaseId", AuditDetailData.lmsCaseId);
        formData.append("addedBy", auth.id);

        // Dispatch the FormData to upload function
        if (selectedFiles.length !== 0) {
            dispatch(UploadCaseDocument(formData));
            selectedFiles.forEach((file) => {
                const changeData = {
                    mappingId: Environment.defaultValue,
                    auditId: auditId,
                    summary: `Uploaded Document: "${file.name}"`,
                    updatedBy: auth.id,
                    updatedByName: auth.user,
                }; dispatch(AddChangeLog(changeData));
            });

        }

    };
    // Trigger file input click
    const triggerFileInput = () => {
        fileInputRef.current.click();
    };
    // Function to determine file icon based on extension
    const getFileIcon = (fileName) => {
        const extension = fileName.split(".").pop().toLowerCase();
        switch (extension) {
            case "pdf":
                return pdfIcon;
            case "xlsx":
            case "xls":
                return excelIcon;
            case "png":
            case "jpg":
            case "jpeg":
                return screenshortIcon;
            default:
                return screenshortIcon; // Default icon for unsupported types
        }
    };
    const handleDownloadFile = async (fileData) => {
        try {
            const data = new FormData();
            data.append("ProjectName", `AR_AUDIT_TOOL`);
            data.append("Location", `AR_AUDIT_TOOL/${fileData.fileId}`);
            data.append("FileName", fileData.fileName);

            // Make the POST request
            const response = await axios.post(
                `${Environment.COMMON_SERVICE}Download/AR_AUDIT_TOOL`,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    responseType: "blob", // Ensure binary data is correctly handled
                }
            );

            // Create a Blob from the response and trigger the download
            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = fileData.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Revoke the object URL
            window.URL.revokeObjectURL(downloadUrl);

        } catch (error) {
            ToastifyService.error("Something Went wrong! ");
            console.error("Error downloading file:", error);
        }
    };

    return (
        <React.Fragment>
            <DocumentUpload
                setShowUploadDocModal={setShowUploadDocModal}
                showUploadDocModal={showUploadDocModal}
            />
            <div className="mb-4">
                <h4 className="fs-16 fw-bold mb-2">Uploaded Documents</h4>
            </div>

            <div>
                {/* {CaseFilesList?.map(data => (
                    <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
                        <div className="d-flex gap-2">
                            <div>
                                <Image
                                    src={getFileIcon(data.fileName)} // Dynamically determine file icon
                                    alt="icon"
                                    width={30}
                                    height={30}
                                />
                            </div>
                            <div>
                                <h4 className="fs-14">{data.fileName}</h4>
                                <h6 className="fs-12 text-black-50">By: {data.addedByName}</h6>
                            </div>
                        </div>

                        <div>
                            <Button variant="outline-dark" className="btn-icon" onClick={() => handleDownloadFile(data)}><i className="ri-download-2-line fs-18" ></i></Button>
                        </div>
                    </div>
                ))} */}

            </div>
            <div className="mb-3 text-center">
            <Button variant="primary" onClick={()=> setShowUploadDocModal({
                    showUploadModelBool: true,
                })}>

                    <i className="ri-file-upload-line align-middle"></i> Upload Documents
                </Button>
            </div>

        </React.Fragment>
    )
}

import Environment from '../../core/Environment';
import jwtInterceptor from '../../core/helpers/jwtInterceptor';

const TeamsList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.USER_URL}GetTeamsList`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const UploadTeams = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.post(`${Environment.USER_URL}UploadTeams`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
export default {
    TeamsList,UploadTeams
  }

import React, { useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    DashboardMenu,
    PendingResponseMenu,
    ReportAnayticsMenu,
    InventoryMenu,
    ProjectsMenu,
    AuditFormMenu,
    ReportMenu,
    SystemSettingMenu,
    QASupervisorAudit
} from "../data/Menu";
import { Badge, Image } from "react-bootstrap";
import logo from "../assets/img/logo/main-logo.png";
import useAuth from "../hooks/useAuth";

const Sidebar = () => {
    const { auth } = useAuth(); // Using the hook inside the functional component
    const scrollBarRef = useRef(null); // Create a ref with useRef

    const toggleFooterMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    };

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <Link to="#" className="sidebar-logo">
                    <Image src={logo} className="wt-200 dark-mode-logo" />
                </Link>
            </div>
            <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
                <SidebarMenu onUpdateSize={() => scrollBarRef.current.updateScroll()} auth={auth} />
            </PerfectScrollbar>
            <div className="sidebar-footer bottom-0">
                {/* Optional footer content */}
            </div>
        </div>
    );
};

const SidebarMenu = ({ auth, onUpdateSize }) => {
    const populateMenu = (m) => {
        const menu = m.map((m, key) => {
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
                    );
                });
            }

            return (
                <li key={key} className="nav-item">
                    {(!sm) ? (
                        <NavLink to={m.link} className="nav-link position-relative">
                            <i className={m.icon}></i>
                            <span>{m.label}</span>
                            <Badge bg="danger" pill className="position-absolute end-0">{m.count}</Badge>
                        </NavLink>
                    ) : (
                        <div onClick={this.toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            );
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    };

    const toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');

        onUpdateSize();
    };

    const toggleSubMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');

        onUpdateSize();
    };

    return (
        <React.Fragment>
            {/* {auth?.roles?.some(role => role === 'Admin') && (
                <div className="nav-group">
                    <div className="nav-label text-dark fs-14 fw-semibold" onClick={toggleMenu}>Dashboard</div>
                    {populateMenu(DashboardMenu)}
                </div>
            )} */}


            {(auth?.roles?.some(role => role === 'Auditor') && (!auth?.roles?.some(role =>role === 'Admin' )))  && (

                <>
                    <div className="nav-group show without-dropdown">
                        {populateMenu(ReportAnayticsMenu)}
                    </div>

                    <div className="nav-group show without-dropdown">
                        {populateMenu(PendingResponseMenu)}
                    </div></>
            )}

            {(auth?.roles?.some(role => role === 'Supervisor') && (!auth?.roles?.some(role =>role === 'Admin' ))) && (

            <>
                {/* <div className="nav-group show without-dropdown">
                    {populateMenu(ReportAnayticsMenu)}
                </div> */}

                <div className="nav-group show without-dropdown">
                    {populateMenu(QASupervisorAudit)}
                </div></>
            )}

            <h5 className="fw-bold text-dark fs-14 mt-3 mb-3 ml-1" style={{ marginLeft: '20px' }}>MANAGE</h5>
            {auth?.roles?.some(role => role === 'Admin') && (
                <>
                    <div className="nav-group show without-dropdown">
                        {populateMenu(InventoryMenu)}
                    </div>

                    <div className="nav-group show without-dropdown">
                        {populateMenu(ProjectsMenu)}
                    </div>

                    <div className="nav-group show without-dropdown">
                        {populateMenu(AuditFormMenu)}
                    </div>
                </>
            )}



            <div className="nav-group show without-dropdown">
                {populateMenu(ReportMenu)}
            </div>
            {auth?.roles?.some(role => role === 'Admin') && (
                <div className="nav-group show without-dropdown">
                    {populateMenu(SystemSettingMenu)}
                </div>
            )}

        </React.Fragment>
    );
};

export default Sidebar;

import { useParams } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { getAuditDetails, getAuditErrors, getLoading, getMapAuditFormErrorsList } from "../../../../application/selector/indexSelector";
import Environment from "../../../../infrastructure/core/Environment";
import { AddChangeLog, UpdateAuditErrorComments } from "../../../../application/action/auditAction";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import LoadingSpinner from "../../../_common/LoadingSpinner";
export default function SupAuditScoreDetail({ ErrorChanges, setAuditorComments, setSupervisorComments, IsAccepted }) {
    const { auditId } = useParams();
    const { auth } = useAuth();
    const dispatch = useDispatch();
    const [errorMessages, setErrorMessages] = useState({});
    const AuditDetailData = useSelector(getAuditDetails)

    const MappedErrorsRaw = useSelector(getAuditErrors) || [];
    const loading = useSelector(getLoading)
    const ErrorsList = useSelector(getMapAuditFormErrorsList) || [];
    const [formData, setFormData] = useState({
        overAllComment: '',
        overAllRebuttal: ''
    })
    // State for toggle switches and rebuttal
    const [toggleStates, setToggleStates] = useState({});
    const [rebuttalToggleStates, setRebuttalToggleStates] = useState({});
    const [rebuttal, setRebuttal] = useState([]);
    useEffect(() => {
        setFormData({
            ...formData, overAllComment: AuditDetailData?.overAllComment,
            overAllRebuttal: AuditDetailData?.overAllRebuttal
        })
    }, [AuditDetailData])
    useEffect(() => {
        if (!ErrorsList.length) return;
        const initialToggleStates = {};
        const initialRebuttal = [];

        ErrorsList.forEach((item) => {
            item.subErrorCategory.forEach((sub) => {
                sub.errorType.forEach((error) => {
                    const key = `${item.errorCategoryId}_${error.id}`;
                    const mapData = MappedErrorsRaw.errorsList?.find((mapped) => mapped.errorId === error.id);
                    const isMet = !MappedErrorsRaw.errorsList?.some((mapped) => mapped.errorId === error.id);

                    if (!initialToggleStates[key]) {
                        initialToggleStates[key] = isMet || false;
                    }
                    
                    if (!isMet && !initialRebuttal.some((r) => r.errorId === error.id)) {
                        initialRebuttal.push({
                            errorId: error.id,
                            auditComment: mapData?.auditComment || "",
                            supervisorRebuttal: mapData?.supComment,
                            mappingId: mapData?.id,
                            met: isMet,
                            errorCategoryId: item.errorCategoryId, // Store categoryId
                        });
                    } else if (mapData?.addedBy === 'auditor') {
                        initialRebuttal.push({
                            errorId: error.id,
                            auditComment: mapData?.auditComment || "",
                            supervisorRebuttal: mapData?.supComment,
                            mappingId: mapData?.id,
                            met: isMet,
                            errorCategoryId: item.errorCategoryId, // Store categoryId
                        });
                    }else {
                        initialRebuttal.push({
                            errorId: error.id,
                            remark: mapData?.auditComment,
                            supervisorRebuttal: mapData?.supComment || "",
                            mappingId: mapData?.id,
                            met: isMet,
                            accept: mapData?.isRebutAccepted,
                            errorCategoryId: item.errorCategoryId, // Store categoryId
                        });
                    }
                });
            });
        });

        setToggleStates(initialToggleStates);
        setRebuttal(initialRebuttal);
        
        ErrorChanges(initialRebuttal); // Notify parent component
    }, [ErrorsList, MappedErrorsRaw]);

    const handleRebuttalToggle = (checked, errorCategoryId, errorId, auditComment) => {
        const key = `${errorCategoryId}_${errorId}`;

        setRebuttalToggleStates((prevStates) => ({
            ...prevStates,
            [key]: checked,
        }));

        setRebuttal((prevRebuttal) => {
            const updatedRebuttal = prevRebuttal.map((remark) =>
                remark.errorId === errorId
                    ? { ...remark, met: checked } // Update existing
                    : remark
            );

            if (!updatedRebuttal.some((r) => r.errorId === errorId)) {
                updatedRebuttal.push({
                    errorId,
                    auditComment: auditComment || "",
                    supervisorRebuttal: "",
                    mappingId: null,
                    met: checked,
                    errorCategoryId,
                });
            }

            // ErrorChanges(updatedRebuttal);
            return updatedRebuttal;
        });
    };


    const handleRebuttalChange = (errorId, value) => {
        setRebuttal((prevRebuttal) =>
            prevRebuttal.map((remark) =>
                remark.errorId === errorId
                    ? { ...remark, supervisorRebuttal: value }
                    : remark
            )
        );

        //ErrorChanges(rebuttal); // Notify parent if required
    };


    const submitRebuttal = () => {
        const formattedData = rebuttal
            .filter(data => {
                if (!data.auditComment || !data.supervisorRebuttal) return false;
                // Exclude unchanged comments
                const existingError = MappedErrorsRaw?.errorsList?.find(mapped => mapped.errorId === data.errorId);
                return !(existingError && existingError.supComment === data.supervisorRebuttal);
            })
            .map(data => ({
                id: data.mappingId || Environment.defaultValue,
                auditorComment: data.auditComment,
                supervisorRebuttal: data.supervisorRebuttal,
                errorId: data.errorId,
                auditId: auditId,
                addedBy: 'auditor',
                updatedBy: auth.id,
            }));

        if (formattedData.length !== 0) {
            dispatch(UpdateAuditErrorComments(formattedData));
        }
        formattedData.forEach((data) => {
            const changeData = {
                mappingId: data.id || Environment.defaultValue,
                auditId: data.auditId,
                summary: `Added Rebuttal: "${data.supervisorRebuttal}" for Comment: "${data.auditorComment}"`,
                updatedBy: auth.id,
                updatedByName: auth.user,
            };

            dispatch(AddChangeLog(changeData));
        });

        setRebuttalToggleStates({});
    };

    return (
        <React.Fragment>
            {ErrorsList.filter((sub) =>
                sub.subErrorCategory.some((data) => data.errorType?.length > 0)
            ).map((item, index) => (
                <Card key={index} className="card-one sub-error-card h-auto mb-3">
                    <Card.Header className="justify-content-between">
                        <div>
                            <h3 className="fs-16 fw-bold">{item.errorCategoryName}</h3>
                        </div>
                        <div>
                            <div className="overall-score-progress wt-200">
                                <div className="progress ht-20" style={{ maxWidth: "100%" }}>
                                    {(() => {
                                        const totalErrors = item.subErrorCategory.reduce(
                                            (total, sub) => total + sub.errorType.length,
                                            0
                                        );
                                        const metCount = item.subErrorCategory.reduce(
                                            (count, sub) =>
                                                count +
                                                sub.errorType.filter((error) => toggleStates[`${item.errorCategoryId}_${error.id}`])
                                                    .length,
                                            0
                                        );

                                        const metPercentage = totalErrors > 0 ? (metCount / totalErrors) * 100 : 0;
                                        const notMetPercentage = 100 - metPercentage;


                                        return (
                                            <>
                                                <div
                                                    className="progress-bar bg-success"
                                                    style={{ width: `${metPercentage}%` }}
                                                >
                                                    <span>{Math.round(metPercentage)}%</span>
                                                </div>
                                                <div
                                                    className="progress-bar bg-danger"
                                                    style={{ width: `${notMetPercentage}%` }}
                                                >
                                                    <span>{Math.round(notMetPercentage)}%</span>
                                                </div>
                                            </>
                                        );
                                    })()}
                                </div>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {item.subErrorCategory
                            .filter((data) => data.errorType.length > 0) // Only include categories with error types
                            .map((data) =>
                                <div className="sub-error-view" key={data.error_type}>
                                    <Row className="mb-3">
                                        <Col md={3} xs={12}><h3 className="fs-16 fw-semibold">{data.subErrorCatName}</h3></Col>
                                        <Col md={3} xs={12}> <h3 className="fs-14 fw-semibold">Auditor Comments</h3></Col>
                                        <Col md={3} xs={12}><h3 className="fs-14 fw-semibold">Supervisor Comments</h3></Col>
                                        <Col md={2} xs={12}> <h3 className="fs-16 fw-semibold wt-50">Score</h3></Col>
                                        <Col md={1} xs={12}></Col>
                                    </Row>

                                    {data.errorType.map((error) => (
                                        <Row className="mb-2 align-items-center g-2 position-relative"
                                            key={`${item.errorCategoryId}_${error.id}`}>
                                            <Col md={3} xs={12}>
                                                <div className="d-flex gap-2 justify-content-between  align-items-center" >
                                                    {toggleStates[`${item.errorCategoryId}_${error.id}`] ? (
                                                        <Badge bg="success" className="text-uppercase fs-12 fw-semibold" pill>Met</Badge>
                                                    ) : (
                                                        <Badge bg="danger" className="text-uppercase fs-12 fw-semibold" pill>Not Met</Badge>
                                                    )}
                                                    <h4 className="fs-14 wt-300">{error.errorName}</h4>
                                                </div>
                                            </Col>
                                            <Col md={3} xs={12}>
                                                {!toggleStates[`${item.errorCategoryId}_${error.id}`] && (
                                                    <h4 className="fs-14 wt-300">{rebuttal.find((remark) => remark.errorId === error.id)?.auditComment}</h4>
                                                )}
                                            </Col>
                                            <Col md={3} xs={12}>
                                                {(rebuttalToggleStates[`${item.errorCategoryId}_${error.id}`] ||
                                                    rebuttal.find((remark) => remark.errorId === error.id)?.supervisorRebuttal) && (
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter rebuttal comment"
                                                            value={
                                                                rebuttal.find((remark) => remark.errorId === error.id)?.supervisorRebuttal || ""
                                                            }
                                                            onChange={(e) => handleRebuttalChange(error.id, e.target.value)}
                                                        />
                                                    )}
                                            </Col>

                                            <Col md={2} xs={12}>
                                                <Row className="g-3">
                                                    {(rebuttalToggleStates[`${item.errorCategoryId}_${error.id}`]) && (
                                                        <Col xl={4} xs={12}>
                                                            <div className="text-end">
                                                                <Button type="button" variant="outline-danger" className="fw-bold text-uppercase me-3" onClick={submitRebuttal}>Submit</Button>
                                                            </div>
                                                        </Col>
                                                    )}

                                                    {!IsAccepted && !MappedErrorsRaw?.errorsList?.find(data => data.errorId === error.id)?.supComment && !toggleStates[`${item.errorCategoryId}_${error.id}`] && !rebuttalToggleStates[`${item.errorCategoryId}_${error.id}`] && (
                                                        <Col xl={4} xs={12}>
                                                            <div className="text-end">
                                                                <Button
                                                                    type="button"
                                                                    variant="warning"
                                                                    className="text-white fw-bold text-uppercase me-3"
                                                                    onClick={(e) =>
                                                                        handleRebuttalToggle(
                                                                            true,
                                                                            item.errorCategoryId,
                                                                            error.id,
                                                                            MappedErrorsRaw?.errorsList?.find(data => data.errorId === error.id)?.auditComment
                                                                        ) // Pass categoryId
                                                                    }
                                                                >
                                                                    Rebuttal
                                                                </Button>
                                                            </div>

                                                        </Col>
                                                    )}
                                                    {MappedErrorsRaw?.errorsList?.find(data => data.errorId === error.id)?.supComment && (
                                                        <Col xl={4} xs={12}>
                                                            <div className="text-end">
                                                                <Button
                                                                    disabled={loading.loading && loading?.value === "auditErrorsList"}
                                                                    type="button" variant="primary" className="fw-bold text-uppercase me-3" onClick={submitRebuttal}
                                                                >
                                                                    {loading.isLoading && loading?.value === "auditErrorsList" ? (
                                                                        <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
                                                                    ) : (
                                                                        "Rebutted"
                                                                    )}
                                                                </Button>
                                                                {/* <Button type="button" variant="primary" className="fw-bold text-uppercase me-3" onClick={submitRebuttal}>Update</Button> */}
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                            <Col md={1} xs={12}>
                                                <Row className="g-3">
                                                    <div className="d-flex align-items-center  sub-error-list">
                                                        <div className="wt-50 text-center">
                                                            <h5
                                                                className="error-point"
                                                                style={{
                                                                    backgroundColor: !toggleStates[`${item.errorCategoryId}_${error.id}`]
                                                                        ? error.points === 1
                                                                            ? "#FFE599"
                                                                            : error.points === 5
                                                                                ? "#FBD38D"
                                                                                : error.points === 10
                                                                                    ? "#F6BB7D"
                                                                                    : error.points === 20
                                                                                        ? "#EC8D5E"
                                                                                        : error.points === 50
                                                                                            ? "#CC0000"
                                                                                            : "#cccccc"
                                                                        : "#cccccc",
                                                                }}
                                                            >
                                                                {error.points}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </Col>

                                        </Row>
                                    ))}
                                </div>
                            )}
                    </Card.Body>
                </Card>
            ))}

            <Card className="card-one h-auto">
                <Card.Body>
                    <h3 className="fs-14 mb-3 fw-bold">Remark</h3>
                    <Row className="g-3">
                        <Col xl={6} md={6} sm={12}>
                            <Form.Label>Auditor's Remark</Form.Label>
                            <Form.Control as="textarea" value={formData.overAllComment} onChange={(e) => { setAuditorComments(e.target.value); setFormData({ ...formData, overAllComment: e.target.value }) }}
                                rows={3} placeholder="Write your message..." disabled />
                        </Col>
                        <Col xl={6} md={6} sm={12}>
                            <Form.Label>Supervisor Remark</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={formData.overAllRebuttal}
                                onChange={(e) => { setSupervisorComments(e.target.value); setFormData({ ...formData, overAllRebuttal: e.target.value }) }}
                                rows={3}
                                placeholder="Write your message..."
                                disabled={IsAccepted}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}

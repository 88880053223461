// src/components/ProgressBar.js
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = ({ progress }) => {
    return (
        <div
          style={{
            width: "100%",
            height: "20px",
            backgroundColor: "#e0e0e0", // Background color of the progress bar
            borderRadius: "5px", // Rounded corners
            overflow: "hidden", // Ensures the inner bar doesn't overflow
            position: "relative",
          }}
        >
          <div
            style={{
              width: `${progress}%`, // Width based on progress
              height: "100%",
              backgroundColor: "#76c7c0", // Color of the progress bar
              borderRadius: "5px", // Rounded corners
              transition: "width 0.3s ease", // Smooth transition for progress updates
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#000", // Text color
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {progress}%
          </div>
        </div>
      );
    };

export default ProgressBar;
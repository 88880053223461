import React, { useEffect, useRef, useState } from "react";
import Header from "../../../layouts/Header";
import "./audit-details.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col, Badge, Form, Button, Tabs, Tab, Image, Nav } from "react-bootstrap";
import CaseData from "./pages/case-data";
import { Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { AddChangeLog, GetAuditCaseDocument, GetAuditChangeLog, GetAuditDetails, GetClosedCasesList, MapAuditError, PerformAudit } from "../../../application/action/auditAction";
import { getAuditDetails, getAuditErrors, getChangeLog, getMapAuditFormErrorsList, getUserDetail, getUserList } from "../../../application/selector/indexSelector";
import { MapAuditFormErrorList } from "../../../application/action/projectAction";
import ATAScore from "../AuditDetail/pages/ATAScore/ata-score";
import useAuth from "../../../hooks/useAuth";
import { GetAllUsers, GetUserDetails } from "../../../application/action/userAction";
import { ErrorCategoryList } from "../../../application/action/errorAction";
import Environment from "../../../infrastructure/core/Environment";
import ChangeLogModal from "../AuditDetail/pages/change-log";
import LoadingBar from "react-top-loading-bar";
import { format } from "date-fns";
import AuditScoreDetail from "../AuditDetail/pages/AuditScore/audit-score-detail";
import jwtInterceptor from "../../../infrastructure/core/helpers/jwtInterceptor";
import ATAScoreDetail from "../AuditDetail/pages/ATAScore/ata-score-detail";
import UploadedDocument from "../AuditDetail/pages/uploaded-documents";
import FinalATAScore from "../AuditDetail/pages/AuditScore/final-ata-score-detail";

export default function AuditDetailsPage() {
    const [showChangeLog, setShowChangeLog] = useState(false);
    const [auditorComments, setAuditorComments] = useState('');
    const [IsAccepted, setIsAccepted] = useState(true);
    const [supervisorComments, setSupervisorComments] = useState(false);
    const [markedErrors, setMarkedErrors] = useState([]);
    const UsersList = useSelector(getUserList)
    const userData = useSelector(getUserDetail)
    const handleChangeLog = (state) => {
        setShowChangeLog(state);
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { auditId } = useParams();
    const { auth } = useAuth();

    //const UsersList = useSelector(getUserList)
    const AuditDetailData = useSelector(getAuditDetails)
    const ErrorsList = useSelector(getMapAuditFormErrorsList) || [];
    const MappedErrors = useSelector(getAuditErrors) || [];


    // USEEFFECT
    useEffect(() => {
        if (UsersList !== undefined) {
            const agentId = UsersList?.find(data => data.fullName === (AuditDetailData?.displayColumns?.find(col => col.colName === 'EMPLOYEE_NAME').colValue))?.id
            if (agentId !== undefined) { dispatch(GetUserDetails(agentId)) }
        }
    }, [UsersList, AuditDetailData])

    // HANDLE ERROR CHANGES FUNCTION
    const handleErrorChanges = (data) => {
        setMarkedErrors(data)
    };

    // USEEFFECT
    useEffect(() => {
        if (auditId !== undefined) {
            dispatch(GetAuditDetails(auditId))
            dispatch(GetAuditChangeLog(auditId))
            dispatch(ErrorCategoryList())
            dispatch(GetAllUsers())
            dispatch(GetAuditCaseDocument(auditId))
        }
    }, [dispatch, auditId])



    // USEEFFECT
    useEffect(() => {
        if (AuditDetailData?.formId !== undefined) {
            dispatch(MapAuditFormErrorList(AuditDetailData?.formId))

        }
    }, [AuditDetailData])

    // Utility functions to calculate points
    const calculatePoints = (ErrorsList, markedErrors, condition) => {
        const data= ErrorsList?.flatMap(category =>
            category.subErrorCategory.flatMap(sub =>
                sub.errorType.filter(error => condition(markedErrors, error))
            )
        );
        return data
    };

    const calculateTotalPoints = errors => {
        return errors.reduce((acc, error) => acc + error.points, 0);
    };

    // Calculation logic
    const remainingErrors = ErrorsList?.flatMap(category =>
        category.subErrorCategory.flatMap(sub =>
            sub.errorType.filter(error =>
                markedErrors?.some(mapped => mapped.errorId === error.id)
            )
        )
    ) || [];
    const scoredErrors = ErrorsList?.flatMap(category =>
        category.subErrorCategory.flatMap(sub =>
            sub.errorType.filter(error =>
                markedErrors?.some(mapped => mapped.errorId === error.id && !mapped.met)
            )
        )
    ) || [];
    const rebuttalErrors = ErrorsList?.flatMap(category =>
        category.subErrorCategory.flatMap(sub =>
            sub.errorType.filter(error =>
                markedErrors?.some(mapped => mapped.errorId === error.id&& !mapped.met && mapped.supervisorRebuttal)
            )
        )
    ) || [];

    const totalPoints = calculateTotalPoints(remainingErrors);
    const scoredPoints = calculateTotalPoints(scoredErrors);
    const rebuttalPoints = calculateTotalPoints(rebuttalErrors);

    const achievedPoints = totalPoints - scoredPoints;
    const rebuttalAchievedPoints = achievedPoints + rebuttalPoints;

    // Calculate percentage
    const percentage = totalPoints > 0
        ? ((achievedPoints / totalPoints) * 100).toFixed(2)
        : 100; // Handle division by zero
 
    // Chart data and options
    const data = {
        labels: ['Good Points', 'Bad Points'],
        datasets: [
            {
                // data: [totalPoints, Math.abs(scoredPoints)],
                // backgroundColor: ['#098911', '#e85353'], // Green for good, red for bad
                data: percentage > 0 ? [achievedPoints, scoredPoints] : [0, 1], // Ensure valid data structure
                backgroundColor: percentage > 0 ? ['#098911', '#e85353'] : ['#e85353', '#e85353'],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        cutout: '80%',
        rotation: -90,
        circumference: 180,
        responsive: true,
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };

    // HANDLE SUBMIT
    const handleSubmit = () => {
        const agent = UsersList?.find(data => data.fullName === (AuditDetailData?.displayColumns?.find(col => col.colName === 'EMPLOYEE_NAME').colValue))
        const formattedData = markedErrors?.filter(data => (data.met && data.mappingId) || (!data.met && !data.mappingId))
            .map((data) => ({
                id: data.mappingId || Environment.defaultValue,
                auditorComment: data.remark, // Include remark
                supervisorRebuttal: data.supervisorRebuttal,
                errorId: data.errorId,
                auditId: auditId,
                updatedBy: auth.id,
                addedBy: 'auditor',
                isRebutAccepted: data.accept || Environment.defaultValue,
                agentEmail:agent.email
            }))
        if (formattedData.length !== 0) {
            dispatch(MapAuditError(formattedData));
        }
    };
    const handleSubmitClose = async () => {
        handleSubmit()
        const agentId = UsersList?.find(data => data.fullName === (AuditDetailData?.displayColumns?.find(col => col.colName === 'EMPLOYEE_NAME').colValue))?.id
        const auditData = {
            id: auditId,
            formId: AuditDetailData.formId,
            projectId: AuditDetailData.projectId,
            caseId: AuditDetailData.caseId,
            datasetName: AuditDetailData.projectName.replace(/ /g, "_"),
            agentId: agentId,
            supervisorId: userData?.supervisorId,
            expectedScore: totalPoints,
            finalScore: achievedPoints,
            auditScore: AuditDetailData.auditorScore,
            ataScore: AuditDetailData.ataScore,
            rebuttalScore: AuditDetailData.rebuttalScore,
            overAllRebuttal: AuditDetailData.overAllRebuttal,
            overAllAuditorComment: AuditDetailData.overAllComment,
            assignedTo: userData?.supervisorId,
            status: "2061156f-e154-4b21-80e6-641677224506",
            auditedBy: auth.id,
            ataBy: AuditDetailData.assignedTo,
            lmsCaseId: AuditDetailData.lmsCaseId
        };

        try {
            const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}createAudit`, auditData, {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    //   authorization: `Bearer ${storedToken}`,
                },
            }
            );
            navigate(`/pendingResponse/manage`);
            const changeData = {
                "mappingId": Environment.defaultValue,
                "auditId": auditId,
                "summary": `Closed the Case`,
                "updatedBy": auth.id,
                "updatedByName": auth.user,
            }
            dispatch(AddChangeLog(changeData))

        } catch (err) {
            console.log(err);

        } finally {
            navigate(`/pendingResponse/manage`);
        }
    }
    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        };
    }, []);


    // Function to determine the default active tab
    const getDefaultActiveKey = () => {
        if (!AuditDetailData?.isATADone) {
            return "audit_score_tab";
        } else if (AuditDetailData?.isATADone) {
            return "ata_score_tab";
        }
        return "auditor_score_tab"; // Fallback to a default tab
    };


    // Set the default active key dynamically
    const defaultTab = getDefaultActiveKey();
    const [activeKey, setActiveKey] = useState(getDefaultActiveKey());

    // Update active key if `AuditDetailData` changes
    useEffect(() => {
        const newKey = getDefaultActiveKey();
        setActiveKey(newKey);
    }, [])

    return (
        <React.Fragment>
            <Header />
            <ChangeLogModal show={showChangeLog} closeFunction={handleChangeLog} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main manage-item-page audit-detail-page ps-0 pe-0" style={{ paddingTop: "70px", backgroundColor: "#F6F8FA" }}>
                <div className="page-top-header d-md-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex gap-2 align-items-center">
                        <Link><i className="ri-arrow-left-s-line fs-22"></i></Link>
                        <h3 className="mb-0 fw-semibold fs-18">{
                            AuditDetailData?.displayColumns?.find(col => col.colName === 'LIMS_CASE_ID')?.colValue || 'N/A'
                        }<Badge className="align-middle" bg="warning" pill>Open</Badge></h3>
                    </div>
                    <div className="d-flex gap-2 top-header-bttns">

                        <Button variant="primary" className="text-uppercase" onClick={handleSubmitClose}>Submit Audit</Button>
                        <Button variant="dark" onClick={() => handleChangeLog(true)}>Change Log</Button>
                    </div>
                </div>


                <Row className="m-0">
                    {/* LEFT AREA */}
                    <Col xl={3} lg={4}>
                        <Card className="card-one audit-details-card">
                            <Card.Body className="overflow-y custom-scroll">
                                <div>
                                    {/* DOUGHNUT CHART VIEW */}
                                    <div>
                                        <div className="doughnut-chart-view" style={{ position: 'relative', width: '100%' }}>
                                            <Doughnut data={data} options={options} />
                                            <div className="doughnut-chart-value">
                                                <div>Overall Score</div>
                                                <div>{percentage} %</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className="border d-flex align-items-center justify-content-between p-3 mb-2">
                                        <div>
                                            <h3 className="fs-16">Audit Score</h3>
                                        </div>
                                        <div>
                                            <b className="fs-16">{AuditDetailData?.finalScore}/{AuditDetailData?.expectedScore}</b>
                                        </div>
                                    </div>

                                    <div className="border d-flex align-items-center justify-content-between p-3 mb-3">
                                        <div>
                                            <h3 className="fs-16">ATA Score</h3>
                                        </div>
                                        <div>
                                            <h3 className="fs-16">{AuditDetailData?.isATADone ? AuditDetailData?.ataScore + '/' + AuditDetailData?.expectedScore : 'Not Performed'}</h3>
                                        </div>
                                    </div>
                                </div>

                                {/* CASE DATA */}
                                <div className="mb-4">
                                    <CaseData />
                                </div>

                                {/* UPLOAD DOCUMENT  */}
                                <div>
                                    <UploadedDocument />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* RIGHT AREA */}
                    <Col xl={9} lg={8}>
                        <Card className="card-one h-auto mb-3">
                            <Card.Body>
                                <Row className="g-3">
                                    <Col xl={2} md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">Rebuttal</h6>
                                        <Form.Label className="mb-0">
                                            <Badge bg="primary" pill>Yes</Badge>
                                        </Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">{AuditDetailData?.auditorName}</h6>
                                        <Form.Label className="mb-0">Auditor Name</Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">{
                                            AuditDetailData?.displayColumns?.find(col => col.colName === 'EMPLOYEE_NAME')?.colValue || 'N/A'
                                        }</h6>
                                        <Form.Label className="mb-0">Employee Name</Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">{
                                            AuditDetailData?.displayColumns?.find(col => col.colName === 'LIMS_CASE_ID')?.colValue || 'N/A'
                                        }</h6>
                                        <Form.Label className="mb-0">LMIS Case ID</Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        {AuditDetailData && (<h6 className="fs-14 fw-bold mb-1">{format(new Date(AuditDetailData?.createdOn), "dd MMM yyyy hh:mm a")}</h6>)}
                                        <Form.Label className="mb-0">Action Date</Form.Label>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <Card className="card-one audit-details-tabs-card">
                                <Card.Header className="p-0 d-block">
                                    <Nav className="m-0" variant="pills">
                                        {/* Auditor Score Tab */}
                                        {(!AuditDetailData?.isATADone) && (
                                            <Nav.Item>
                                                <Nav.Link eventKey="audit_score_tab">
                                                    <div className="d-flex align-items-center justify-content-between d-sm-block">
                                                        <div>Auditor Score</div>
                                                        <div className="text-right text-sm-start">
                                                            <h2 className="fs-16 fw-bold">{AuditDetailData?.finalScore}/{AuditDetailData?.expectedScore}</h2>
                                                            <h4 className="fs-12">Points</h4>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}
                                        {/* Audit Score Tab */}
                                        {(AuditDetailData?.isATADone) && (
                                            <Nav.Item>
                                                <Nav.Link eventKey="auditor_score_tab">
                                                    <div className="d-flex align-items-center justify-content-between d-sm-block">
                                                        <div>Audit Score</div>
                                                        <div className="text-right text-sm-start">
                                                            <h2 className="fs-16 fw-bold">{AuditDetailData?.auditorScore}/{AuditDetailData?.expectedScore}</h2>
                                                            <h4 className="fs-12">Points</h4>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}
                                        {/* ATA Score Tab */}
                                        {(AuditDetailData?.isATADone) && (
                                            <Nav.Item>
                                                <Nav.Link eventKey="ata_score_tab">
                                                    <div className="d-flex align-items-center justify-content-between d-sm-block">
                                                        <div>ATA Score</div>
                                                        <div className="text-right text-sm-start">
                                                            <h2 className="fs-16 fw-bold">{AuditDetailData?.ataScore}/{AuditDetailData?.expectedScore}</h2>
                                                            <h4 className="fs-12">Points</h4>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}
                                    </Nav>
                                </Card.Header>

                                <Card.Body className="overflow-y custom-scroll p-0" style={{ backgroundColor: "#f5f7f9" }}>
                                    <Tab.Content>
                                        {/* AUDIT SCORE TAB */}
                                        <Tab.Pane eventKey="audit_score_tab">
                                            <Card className="card-one audit-details-tabs-card">
                                                <Card.Body className="overflow-y custom-scroll p-0" style={{ backgroundColor: "#f5f7f9" }}>
                                                    <ATAScoreDetail ErrorChanges={handleErrorChanges} setAuditorComments={setAuditorComments} setSupervisorComments={setSupervisorComments} />
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        {/* AUDITOR SCORE TAB */}
                                        <Tab.Pane eventKey="auditor_score_tab">
                                            <Card className="card-one audit-details-tabs-card">
                                                <Card.Body className="overflow-y custom-scroll p-0" style={{ backgroundColor: "#f5f7f9" }}>
                                                    <AuditScoreDetail IsAccepted={IsAccepted} ErrorChanges={handleErrorChanges} setAuditorComments={setAuditorComments} setSupervisorComments={setSupervisorComments} />
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        {/* ATA SCORE TAB */}
                                        <Tab.Pane eventKey="ata_score_tab">
                                            <Card className="card-one audit-details-tabs-card">
                                                <Card.Body className="overflow-y custom-scroll p-0" style={{ backgroundColor: "#f5f7f9" }}>
                                                    <FinalATAScore ErrorChanges={handleErrorChanges} setAuditorComments={setAuditorComments} setSupervisorComments={setSupervisorComments} />
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Card.Body>
                            </Card>
                        </Tab.Container>

                    </Col>
                </Row>
                {/* )}
                {loading && value === "itemList" && items?.result === undefined && (
                  <CardShimmerLoader columnCount={4} rowCount={4} />
                )} */}
            </div>
        </React.Fragment>
    )
}
import React, { useContext, useState } from "react";
import { Button, Table } from "react-bootstrap";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getMapProjectList, getProjectList } from "../../../application/selector/indexSelector";
import { MapProjectAuditForm } from "../../../application/action/projectAction";
import AuthContext from "../../../context/AuthProvider";

export default function MappedUsers(props) {
    // HANDLE DELETE PROJECT
    const [data, setData] = useState([]);
    const { auth } = useContext(AuthContext)
    const dispatch = useDispatch();
    const MappedProjectsList = useSelector(getMapProjectList)
    const ProjectsList = useSelector(getProjectList);
    const handleDelete = (index) => {
        SweetAlertService.showAlert(
            "Project Name",
            "Are you sure you want to delete this Project Name?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    ToastifyService.success(`Project Name Deleted Successfully`);
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };
    const associatedDataOption = ProjectsList?.filter((formdata) => 
        !MappedProjectsList?.some((mapped) => mapped.projectId === formdata.id))?.map((form) => ({
        value: form.id,           // Using form.id for the value
        label: form.projectName   // Using form.auditFormName for the label
    }));
    const [selectedAUF, setSelectedAUF] = useState([]);
    const handleAuditFormChange = (selectedOptions) => {
        setSelectedAUF([selectedOptions]); // Update state with selected options
    };
    const handleMapAuditForm = () => {
        const Data = selectedAUF?.map(element => ({
            projectId: element.value,
            formId: props?.formId, // Use `element.value` as formId from Select
            createdBy: auth.id,
            modifiedBy: auth.id
        }));
        dispatch(MapProjectAuditForm(Data)); // Dispatch the action with Data
    };
    return (
        <React.Fragment>
            <div className="mb-3 d-flex justify-content-end gap-3 position-relative z-index-10">
                <div className="d-flex gap-2">
                    <Select
                        className="wt-300"
                        options={associatedDataOption} // Dropdown options
                        value={selectedAUF} // Bind to selected teams state
                        onChange={handleAuditFormChange} // Update state on selection change
                        isSearchable={true}

                        placeholder="Select Mapped Project" // Placeholder text
                    />

                    <Button type="button" variant="dark" onClick={handleMapAuditForm}>Include</Button>
                </div>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Project Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {MappedProjectsList?.map((data, index) => (
                        <tr key={data.id || index}>
                            <td>{data.projectName}</td>
                            <td>
                                <Button variant="outline-danger" onClick={() => handleDelete(true)} className="btn-icon"><i className="ri-delete-bin-line"></i></Button>
                            </td>
                        </tr>
                    ))}


                </tbody>
            </Table>
        </React.Fragment>
    )
};
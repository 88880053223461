import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Offcanvas, Row, Tab, Tabs } from "react-bootstrap";
import AuditFormList from "./audit-form-list";
import MappedUsers from "./mapped-users";
import Environment from "../../../infrastructure/core/Environment";
import useAuth from "../../../hooks/useAuth";
import ProjectData from "./projectData";
import { useDispatch, useSelector } from "react-redux";
import { getMapProjectColumnList, getProjectAuditFormListById } from "../../../application/selector/indexSelector";
import { GetProjectAuditFormListById, MapProjectAuditorList, MapProjectColumnList } from "../../../application/action/projectAction";

export default function UpdateProject(props) {
    const { auth } = useAuth();
    const dispatch = useDispatch()
    const mappedColumnList = useSelector(getMapProjectColumnList)
    const ProjectAuditFormList = useSelector(getProjectAuditFormListById);

    useEffect(() => {
        if (props?.selectedProject?.id !== undefined) {
            dispatch(MapProjectColumnList(props?.selectedProject?.id)); // Fetch columns
            dispatch(GetProjectAuditFormListById(props?.selectedProject?.id)); // Fetch audit form list
            dispatch(MapProjectAuditorList(props?.selectedProject?.id)); // Fetch audit form list
        }
    }, [props?.selectedProject?.id]);

    const initialState = {
        projectName: "",
        projectDescription: "",
        teamId: Environment.defaultValue,
        sourceId: Environment.defaultValue,
        empId: "",
        createdBy: auth.id,
        modifiedBy: auth.id
    }
    const [formData, setFormData] = useState(initialState);
    useEffect(() => {
        if (props.selectedProject !== null) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                projectName: props?.selectedProject?.projectName,
                projectDescription: props?.selectedProject?.projectDescription,
            }));
        }
    }, [props?.selectedProject]);

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-80">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Update Project</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Card className="card-one h-auto mb-4">
                        <Card.Body>
                            <div className="mb-4">
                                <h2 className="fs-16 text-dark d-flex align-items-center mb-1 fw-semibold">{formData.projectName}</h2>
                                <h6 className="fs-12">{formData.projectDescription}</h6>
                            </div>

                            <Row>
                                <Col>
                                    <h5 className="fs-12 text-dark">Team Name</h5>
                                    <h3 className="fs-16">{props?.selectedProject?.teamName}</h3>
                                </Col>
                                <Col>
                                    <h5 className="fs-12 text-dark">Fields</h5>
                                    <h3><Badge pill>{mappedColumnList?.length}</Badge></h3>
                                </Col>
                                <Col>
                                    <h5 className="fs-12 text-dark">Audit Form</h5>
                                    <h3><Badge pill>{ProjectAuditFormList?.length}</Badge></h3>
                                </Col>
                                <Col>
                                    <h5 className="fs-12 text-dark">Created On</h5>
                                    <h3 className="fs-16">03 Oct 2024</h3>
                                    <h6 className="fs-12">12:10 PM</h6>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>


                    <div className="common-tabs">
                        <Tabs defaultActiveKey="projects_tab" className="sale-on-point-tabs">
                            <Tab eventKey="projects_tab" title={<><h6 className="fs-14 mb-0">Project</h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <ProjectData data={props.selectedProject} closeFunction={ props?.closeFunction} />
                                </div>
                            </Tab>

                            <Tab eventKey="audit_tabtab" title={<><h6 className="fs-14 mb-0">Audit Form</h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <AuditFormList Id={props?.selectedProject?.id} />
                                </div>
                            </Tab>

                            <Tab eventKey="mapped_users_tabtab" title={<><h6 className="fs-14 mb-0">Mapped Auditor</h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <MappedUsers Id={props?.selectedProject?.id}/>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}
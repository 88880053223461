import React, { useEffect, useState } from "react";
import { Form, Offcanvas, Button, Row, Col, Table, Card, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import { getSubErrorList } from "../../../application/selector/indexSelector";
import Environment from "../../../infrastructure/core/Environment";
import Select from "react-select";
import { MapAuditFormError } from "../../../application/action/projectAction";
import AuditErrorType from "./audit-error-type";

export default function AuditSubError(props) {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const SubErrorCategoryList = useSelector(getSubErrorList);

    const [selectedErrors, setSelectedErrors] = useState([]); // Changed to array for multi-select
    const [addedErrors, setAddedErrors] = useState([]);

    const initialState = {
        mappingId: Environment.defaultValue,
        errorId: Environment.defaultValue,
        errorCategoryId: Environment.defaultValue,
        subErrorCategoryId: Environment.defaultValue,
        formId: Environment.defaultValue,
        points: 0,
        createdBy: auth.id,
        modifiedBy: auth.id
    };

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        setFormData({
            ...formData,
            mappingId: props?.mappingId || Environment.defaultValue,
            errorCategoryId: props?.selectedCategory,
            formId: props?.data?.formId,
            createdBy: auth.id,
            modifiedBy: auth.id
        });
    }, [props]);

    // Generate options for the dropdown (filter out already added errors)
    const selectSubErrorOptions = (SubErrorCategoryList?.map((data) => ({
        value: data.id,
        label: data.subErrorName || 'Unknown Category',
    })) || []).filter(
        (category) => !addedErrors.some(added => added.value === category.value)
    );

    // Handle multi-select changes
    const handleSubErrorChange = (selectedOptions) => {
        setSelectedErrors(selectedOptions || []);
    };

    // Add multiple selected errors to the table
    const handleInclude = () => {
        if (selectedErrors.length > 0) {
            const newErrors = selectedErrors.map(error => ({
                ...error,
                points: formData.points || 0
            }));

            setAddedErrors(prev => [...prev, ...newErrors]);
            setSelectedErrors([]); // Clear selection
        }
    };

    // Remove an error from the table
    const handleDelete = (value) => {
        setAddedErrors(prev => prev.filter(error => error.value !== value));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = addedErrors.map((error) => ({
            ...formData,
            subErrorCategoryId: error.value,
            points: error.points
        }));

        dispatch(MapAuditFormError(payload));

        // Reset state
        setFormData(initialState);
        setAddedErrors([]);
        props.closeFunction();
    };

    const [showAuditErrorType, setShowAuditErrorType] = useState(false);
    const handleAuditErrorType = (state)=> {
        setShowAuditErrorType(state);
    }

    return (
        <React.Fragment>
            <AuditErrorType show={showAuditErrorType} closeFunction={handleAuditErrorType} />
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-60">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Sub Error</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <Select
                                        options={selectSubErrorOptions}
                                        value={selectedErrors}
                                        placeholder="Select Sub Errors"
                                        isSearchable={true}
                                        isMulti
                                        onChange={handleSubErrorChange}
                                    />
                                </Col>
                                <Col md={4} className="mt-md-0 mt-2">
                                    <Button
                                        type="button"
                                        variant="dark"
                                        onClick={handleInclude}
                                        disabled={!selectedErrors.length}
                                    >
                                        Add Selected ({selectedErrors.length})
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                        {addedErrors.length > 0 && (
                            <Card className="card-one">
                                <Card.Body className="p-0">
                                    <Table className="mb-0 w-100" responsive>
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Sub Error Name</th>
                                                <th>Error Type</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {addedErrors.map((error) => (
                                                <tr key={error.value}>
                                                    <td>{error.label}</td>
                                                    <td>
                                                        <Badge pill bg="primary">{error.points}</Badge>
                                                    </td>
                                                    <td className="wt-100">
                                                        <div className="d-flex gap-2">
                                                            <Button className="btn-icon" variant="outline-dark" onClick={()=> handleAuditErrorType(true)}>
                                                                <i class="ri-eye-line"></i>
                                                            </Button>
                                                            <Button className="btn-icon" variant="danger" onClick={() => handleDelete(error.value)}>
                                                                <i className="ri-delete-bin-line"></i>
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        )}

                        {/* <div className="mt-3 d-flex justify-content-end gap-2">
                            <Button variant="secondary" onClick={props.closeFunction}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit" disabled={!addedErrors.length}>
                                Save Changes
                            </Button>
                        </div> */}
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    );
}
import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import UpdateAuditForm from "./update-audit-form";
import { useSelector, useDispatch } from "react-redux";
import { getProjectList } from "../../application/selector/indexSelector";
import { AddAuditForm } from "../../application/action/projectAction";
import {
  validateRequired,
  validateRequiredDropdown,
  validateRequiredNumberDropdown,
  restrictSpace,
  validateEmail,
  restrictConsecutiveSpace,
} from "../../infrastructure/core/validationUtils";
import Environment from "../../infrastructure/core/Environment";
import useAuth from "../../hooks/useAuth";

export default function CreateAuditForm(props) {
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const ProjectsList = useSelector(getProjectList);
  const initialState = {
    projectId: Environment.defaultValue,
    auditFormName: "",
    auditFormDescription: "",
    createdBy: "d8f2f783-4461-4dec-9142-d86c7104944a",
    modifiedBy: "d8f2f783-4461-4dec-9142-d86c7104944a",
  };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const validateInput = () => {
    const newErrors = {};
    // Validate Charge Name
    newErrors.projectId = validateRequiredDropdown(formData.projectId)
      ? ""
      : "Project Name is required";
    newErrors.auditFormName = validateRequired(formData.auditFormName)
      ? ""
      : "Audit Form Name is required";
    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value?.trim() !== "" && value?.trim() !== Environment.defaultValue) {
      setErrors({ ...errors, [name]: "" });
      // Email format validation
      // if (name === "email") {
      //   const isValidEmail = validateEmail(value);
      //   if (!isValidEmail) {
      //     setErrors({
      //       ...errors,
      //       [name]: "Invalid email format",
      //     });
      //     return;
      //   } else {
      //     checkDuplicateEmail(name, value);
      //   }
      // }
    } else {
      setErrors({
        ...errors,
        [name]: `${
          name === "projectId"
            ? "Project Name"
            : name === "auditFormName"
            ? "Audit Form Name"
            : "This Field"
        } is required`,
      });
    }
  };
  const [showUpdateAuditForm, setShowUpdateAuditForm] = useState(false);
  const handleCreateAudit = (state) => {
    setShowUpdateAuditForm(state);
    props.closeFunction(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    dispatch(AddAuditForm(formData));
    handleCreateAudit(false);
    setFormData(initialState);
  };
  return (
    <React.Fragment>
      <UpdateAuditForm
        show={showUpdateAuditForm}
        closeFunction={handleCreateAudit}
      />
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Create New Audit Form
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col md={8}>
              <div className="mb-4">
                <Form.Label>
                  Project Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  className="wt-400"
                  name="projectId"
                  value={formData.projectId}
                  onChange={handleInputChange}
                >
                  <option disabled value={Environment.defaultValue}>Choose Project Name</option>
                  {ProjectsList?.map((pro) => (
                    <option key={pro.id} value={pro.id}>
                      {pro.projectName}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.projectId}
                </Form.Text>
              </div>
            </Col>

            <Col md={8}>
              <div className="mb-4">
                <Form.Label>
                  Audit Form Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Audit Form Name"
                  name="auditFormName"
                  value={formData.auditFormName}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">
                  {errors.auditFormName}
                </Form.Text>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={4}
                  placeholder="Enter Description"
                  name="auditFormDescription"
                  value={formData.auditFormDescription}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create Audit Form</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}

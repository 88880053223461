import * as loaderActions from '../action/loaderAction'
const initialState = {
    loading: true,
    operationId:undefined,
    progress:undefined,
    value: 'default'
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (loaderActions.SET_LOADING_ON):
            return { ...state, loading: action.payload.loading, value: action.payload.value };
        case (loaderActions.SET_LOADING_OFF):
            return { ...state, loading: action.payload.loading, value: action.payload.value };
        case loaderActions.SET_PROGRESS:
            return { ...state, progress: action.payload, };
        case loaderActions.OPERATION_SUCCESS:
            return { ...state, operationId: action.payload, };
        default:
            return state;
    }
}
export default reducer
import React, { useEffect } from "react";
import { Badge, Button, Card, Offcanvas, Tab, Tabs } from "react-bootstrap";
import AuditErrors from "./Pages/audit-errors";
//import MappedFields from "./Pages/mapped-fields";
import MappedUsers from "./Pages/mapped-projects";
import { useDispatch, useSelector } from "react-redux";
import { GetProjectListByAuditFormId, MapAuditFormErrorList } from "../../application/action/projectAction";
import AuditDetailsView from "./audit-detail-view";
import { getErrorCategoryList, getMapProjectList } from "../../application/selector/indexSelector";

export default function UpdateAuditForm(props) {
    const MappedProjectsList = useSelector(getMapProjectList)
    const dispatch = useDispatch();
    useEffect(() => {
        if (props?.data?.formId !== undefined) {
            dispatch(MapAuditFormErrorList(props?.data?.formId))
            dispatch(GetProjectListByAuditFormId(props?.data?.formId))
        }
    }, [props?.data])
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-80">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Update Audit Form</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="common-tabs">
                        <Tabs defaultActiveKey="audit_errors_tab" className="sale-on-point-tabs">
                            <Tab eventKey="audit_errors_tab" title={<><h6 className="fs-14 mb-0">Audit Errors</h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <AuditErrors data={props?.data} closeFunction={props.closeFunction}/>
                                </div>
                            </Tab>

                            {/* <Tab eventKey="audit_details_tab" title={<><h6 className="fs-14 mb-0">Audit Details</h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <AuditDetailsView data={props?.data} />
                                </div>
                            </Tab> */}

                            {/* <Tab eventKey="mapped_fields_tab" title={<><h6 className="fs-14 mb-0">Mapped Fields <Badge pill>6</Badge></h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <MappedFields />
                                </div>
                            </Tab> */}

                            <Tab eventKey="mapped_projects_tab" title={<><h6 className="fs-14 mb-0">Mapped Projects <Badge pill>{MappedProjectsList?.length}</Badge></h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <MappedUsers formId={props?.data?.formId}/>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    
                </Offcanvas.Body>
                <div className="offcanvas-footer justify-content-start">
                       <Button
                         type="submit"
                         variant="primary"
                         className="fs-14 me-2 d-flex align-items-center"
                         onClick={()=>props.closeFunction()}
                       >
                         <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                         <span className="align-middle">Done</span>
                       </Button>
                     </div> 
            </Offcanvas>
        </React.Fragment>
    )
}
// import { LOAD_INVENTORY, LOAD_USER, LOAD_TEAMS, LOAD_ERROR, START_OPERATION, setProgress, OperationSuccess, GET_PROGRESS, pollProgress } from "../action/loaderAction";
import axios from "axios";
import { GET_PROGRESS, OperationSuccess, pollProgress, setProgress, START_OPERATION } from "../action/loaderAction";
import Environment from "../../infrastructure/core/Environment";

// // LOAD INVENTORY FUNCTION
// const loadInventory = ({ log }) => ({ dispatch }) => (next) => (action) => {
//     if (action.type === LOAD_INVENTORY) {
//         log('page loaded');
//         dispatch(inventory.GET_INVENTORY_LIST);
//     }
//     next(action);
// };

// LOAD USER FUNCTION
// const loadUsers = ({ log }) => ({ dispatch }) => (next) => (action) => {
//     if (action.type === LOAD_USER) {
//         log('page loaded');
//         dispatch(users.GetAllUserGroups);
//     }
//     next(action);
// };
const startOperation = ({ api }) => ({ dispatch }) => (next) => async (action) => {
    if (action.type === START_OPERATION) {
        const response = await axios.post(`${Environment.LOADER_URL}project/startAddProject`);
        dispatch(pollProgress(response.data.operationId)); // Get the operation ID
        dispatch(OperationSuccess(response.data.operationId))
    }
    next(action);
};
const getProgress = ({ api }) => ({ dispatch }) => (next) => async (action) => {
    if (action.type === GET_PROGRESS) {
        const response = await axios.get(`${Environment.LOADER_URL}project/getProgress/${action.payload}`);
        const progress = response.data.progress;
        dispatch(setProgress(progress)); // Update progress in Redux
    }
    next(action);
};

// LOAD TEAMS FUNCTION
// const loadTeams = ({ log }) => ({ dispatch }) => (next) => (action) => {
//     if (action.type === LOAD_TEAMS) {
//         log('page loaded');
//         dispatch(teams.GET_TEAMS_LIST);
//     }
//     next(action);
// };

// // LOAD ERRORS FUNCTION
// const loadErrors = ({ log }) => ({ dispatch }) => (next) => (action) => {
//     if (action.type === LOAD_ERROR) {
//         log('page loaded');
//         dispatch(errors.ErrorCategoryList);
//     }
//     next(action);
// };

export default [startOperation, getProgress]
export const GET_INVENTORY_LIST = 'GET_INVENTORY_LIST';
export const UPLOAD_INVENTORY ='UPLOAD_INVENTORY';
export const ADD_DATASOURCE ='ADD_DATASOURCE';
export const GET_DATASOURCE_LIST = 'GET_DATASOURCE_LIST';
export const GET_INVENTORY_DATA_LIST_BYID = 'GET_INVENTORY_DATA_LIST_BYID';
export const GET_INVENTORY_DATA_LIST_BYID_SUCCESS = 'GET_INVENTORY_DATA_LIST_BYID_SUCCESS';
export const GET_INVENTORY_LIST_SUCCESS = 'GET_INVENTORY_LIST_SUCCESS';
export const GET_DATASOURCE_LIST_SUCCESS = 'GET_DATASOURCE_LIST_SUCCESS';
export const GET_ALL_HEADER = 'GET_ALL_HEADER';
export const GET_ALL_HEADER_SUCCESS = 'GET_ALL_HEADER_SUCCESS';
export const GET_DATASET_LIST = 'GET_DATASET_LIST';
export const GET_DATASET_LIST_SUCCESS = 'GET_DATASET_LIST_SUCCESS';



export const GetInventoryList = () => ({
    type: GET_INVENTORY_LIST,
});
export const UploadInventory = (data) => ({
    type: UPLOAD_INVENTORY,
    payload:data
});
export const InventoryListSuccess = (data) => ({
    type: GET_INVENTORY_LIST_SUCCESS,
    payload:data
});
export const AddDataSource = (data) => ({
    type: ADD_DATASOURCE,
    payload:data
});
export const GetDataSourceList = () => ({
    type: GET_DATASOURCE_LIST,
});
export const DataSourceListSuccess = (data) => ({
    type: GET_DATASOURCE_LIST_SUCCESS,
    payload:data
});
export const GetDataSetList = () => ({
    type: GET_DATASET_LIST,
});
export const DataSetListSuccess = (data) => ({
    type: GET_DATASET_LIST_SUCCESS,
    payload:data
});
export const GetInventoryDataListById = (data) => ({
    type: GET_INVENTORY_DATA_LIST_BYID,
    payload:data
});
export const GetInventoryDataListByIdSuccess = (data) => ({
    type: GET_INVENTORY_DATA_LIST_BYID_SUCCESS,
    payload:data
});
export const GetAllHeaders = () => ({
    type: GET_ALL_HEADER
});
export const GetAllHeadersSuccess = (data) => ({
    type: GET_ALL_HEADER_SUCCESS,
    payload:data
});
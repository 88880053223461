import React from "react";
import { Button, Offcanvas, Tabs, Tab } from "react-bootstrap";
//import { useDispatch, useSelector } from "react-redux";
//import { GetProjectListByAuditFormId, MapAuditFormErrorList } from "../../../application/action/projectAction";
import AditError from "./audit-error";
import MappedProject from "./mapped-project";
import AuditDetails from "./audit-details";

export default function EditAuditForm(props) {
    // const dispatch = useDispatch();
    // const ErrorCategoryList = useSelector(getErrorCategoryList);
 

    // useEffect(() => {
    //     if (props?.data?.formId !== undefined) {
    //         dispatch(MapAuditFormErrorList(props?.data?.formId));
    //         dispatch(GetProjectListByAuditFormId(props?.data?.formId));
    //         console.log("Get Console Log", ErrorCategoryList);
    //     }
    // }, [props?.data, dispatch, ErrorCategoryList]);

   

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-70">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Update Audit Form</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                <div className="common-tabs">
                    <Tabs defaultActiveKey="audit_errors_tab" className="sale-on-point-tabs">

                        {/* AUDIT ERROR */}
                        <Tab eventKey="audit_errors_tab" title={<><h6 className="fs-14 mb-0">Audit Errors</h6></>} tabClassName="custom-tab-header">
                              <div className="custom-tab-body">
                                    <AditError data={props?.data} />
                              </div>
                        </Tab>

                        {/* MAPPED PROJECT TAB */}
                        <Tab eventKey="mapped_projects_tab" title={<><h6 className="fs-14 mb-0">Mapped Projects</h6></>} tabClassName="custom-tab-header">
                              <div className="custom-tab-body">
                                    <MappedProject />
                              </div>
                        </Tab>

                        {/* AUDIT DETAILS TAB */}
                        <Tab eventKey="audit_details" title={<><h6 className="fs-14 mb-0">Audit Details</h6></>} tabClassName="custom-tab-header">
                              <div className="custom-tab-body">
                                    <AuditDetails />
                              </div>
                        </Tab>
                    </Tabs>
                </div>
                 
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import Header from "../../layouts/Header";
import { Button, Card, Form } from "react-bootstrap";
import SweetAlertService from "../_common/SweetAlertService";
import ToastifyService from "../_common/ToastifyService";
import CreateProject from "./Create/create-project";
import UpdateProject from "./Update/update-project";
import { useDispatch, useSelector } from "react-redux";
import { TeamsList, UploadTeams } from "../../application/action/TeamsAction";
import { GetAllHeaders, GetDataSetList, GetDataSourceList } from "../../application/action/inventoryAction";
import { getProjectList, getLoading, getAuditorList } from "../../application/selector/indexSelector";
import TableShimmerLoader from "../_common/ShimmerTable";
import { DeleteProject, GetAuditFormList, GetProjectDetails, GetProjectList } from "../../application/action/projectAction";
import { GetAllAuditors } from "../../application/action/userAction";
import LoadingBar from 'react-top-loading-bar';
import { format } from "date-fns";
import AuthContext from "../../context/AuthProvider";

export default function Projects() {
    const dispatch = useDispatch()
    const [showCreateProject, setShowCreateProject] = useState(false);
    const { loading, value } = useSelector(getLoading);
    const ProjectsList = useSelector(getProjectList);

    // HANDLE CREATE PROJECT
    const handleCreateProject = (state) => {
        setShowCreateProject(state);
    };

    // USEEFFECT
    useEffect(() => {
        dispatch(GetDataSourceList());
        dispatch(GetDataSetList());
        dispatch(TeamsList());
        dispatch(GetAllHeaders());
        dispatch(GetProjectList());
        dispatch(GetAuditFormList())
        dispatch(GetAllAuditors(3))
    }, [])

    const columns = [
        {
            name: "Project Name",
            selector: (row) => row.projectName,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Team Name",
            selector: (row) => row.projectName,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Fields",
            selector: (row) => (
                <h6 className={`fs-14 ${row.columnCount === 0 ? "text-danger" : "text-dark fw-bold"}`} pill>
                    {row.columnCount === 0 ? "Not Mapped" : row.columnCount + " " + "mapped"}
                </h6>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Audit Form",
            selector: (row) => (
                <h6 className={`fs-14 ${row.formCount === 0 ? "text-danger" : "text-dark fw-bold"}`} pill>
                    {row.formCount === 0 ? "Not Found" : row.formCount}
                </h6>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Created On",
            selector: (row) => (
                <div>
                    <h6 className="mb-0 ">{format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a")}</h6>
                </div>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    <Button variant="primary" className="btn-icon me-2" onClick={() => handleUpdateProject(true, row)}>
                        <i className="ri-pencil-line"></i>
                    </Button>
                    <Form.Check
                        type="switch"
                        id={`custom-switch-${row.id}`}
                        checked={row.status === '5e03965f-c410-46b3-8713-b5058348ab02'} // Check if status matches
                        className="mb-0"
                        onChange={(e) => handleStatusChange(row.id, e.target.checked)} // Handle status change
                        custom
                    />
                </div>
            ),
            width: "200px",
        },
    ];
    
    // Function to handle status change
    const handleStatusChange = (id, isChecked) => {
        const newStatus = isChecked ? '5e03965f-c410-46b3-8713-b5058348ab02' : '5e03965f-c410-46b3-8713-b5058348ab03';
    
        // Update the status in the backend
        dispatch(DeleteProject({ id, status: newStatus }))
            .then(() => {
                // Update the status in the local state
                const updatedData = data.map((item) =>
                    item.id === id ? { ...item, status: newStatus } : item
                );
                setData(updatedData);
                ToastifyService.success(`Status updated successfully`);
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Failed to update status`);
            });
    };
    
    // Function to handle project deletion
    // const handleDeleteProject = (index, id) => {
    //     SweetAlertService.showAlert(
    //         "Project",
    //         "Are you sure you want to delete this Project?",
    //         "warning"
    //     )
    //     .then((result) => {
    //         if (result.isConfirmed) {
    //             dispatch(DeleteProject(id))
    //                 .then(() => {
    //                     const newData = [...data];
    //                     newData.splice(index, 1);
    //                     setData(newData);
    //                     ToastifyService.success(`Project deleted successfully`);
    //                 })
    //                 .catch((error) => {
    //                     console.error("Error:", error);
    //                     ToastifyService.error(`Failed to delete project`);
    //                 });
    //         }
    //     });
    // };

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProject, setSelectedProject] = useState({});
    const filteredProjectList = ProjectsList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // HANDLE UPDATE PROJECT
    const [showUpdateProject, setShowUpdateProject] = useState(false);
    const handleUpdateProject = (state, data) => {
        if (data !== undefined) {
            dispatch(GetProjectDetails(data?.id))
        }
        setShowUpdateProject(state);
        setSelectedProject(data)
    };

    // HANDLE DELETE PROJECT
    const [data, setData] = useState([]);
   
    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 1000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);
 const fileInputRef = useRef(null);
 const {auth} = useContext(AuthContext)
  const handleFileUpload = (event) => {
         const files = event.target.files;
 
         if (!files.length) return;
         const formData = new FormData();
         formData.append("CreatedBy", auth.id);
         formData.append("File", files[0]); // Use the first file
 
         dispatch(UploadTeams(formData)); // Assuming `dispatch` is correctly set up
     
 
     };
 
     const handleBulkUpload = () => {
         fileInputRef.current.click();
     };
    return (
        <React.Fragment>
            <Header />
            <CreateProject show={showCreateProject} closeFunction={handleCreateProject} />
            <UpdateProject show={showUpdateProject} closeFunction={handleUpdateProject} selectedProject={selectedProject} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <h4 className="fs-16">Projects</h4>
                    <Button variant="primary" className="d-flex align-items-center gap-1" onClick={() => handleCreateProject(true)}>
                        <i className="ri-add-line fs-16"></i>
                        <span>Create New Project</span>
                    </Button>
                </div>
  <div>
                        <Form.Control
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            accept=".xls,.xlsx,.csv"
                            onChange={handleFileUpload}
                        />
                        <Button variant="primary" onClick={handleBulkUpload}>
                            <i className="ri-file-excel-2-line align-middle"></i> Upload Teams
                        </Button>
                    </div>
                <Card className="card-one">
                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">List of Projects</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="custom-common-table">
                            {filteredProjectList && filteredProjectList !== undefined && (
                                <DataTable
                                    columns={columns}
                                    data={filteredProjectList}
                                    fixedHeader
                                    highlightOnHover
                                    pagination
                                // Enable sorting
                                />
                            )}
                            {!loading && filteredProjectList === undefined && (
                                <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                            )}
                            {loading &&
                                value === "ProjectList" && filteredProjectList === undefined && (
                                    <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                                )}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}

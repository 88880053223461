import React, { useEffect, useState } from "react";
import { Form, Offcanvas, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getErrorList, getMapAuditFormErrorsList, getPointList } from "../../../../application/selector/indexSelector";
import Environment from "../../../../infrastructure/core/Environment";
import useAuth from "../../../../hooks/useAuth";
import { MapAuditFormError, MapAuditFormErrorType } from "../../../../application/action/projectAction";

export default function ErrorModal(props) {
    const [selectedError, setSelectedError] = useState(null);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const ErrorList = useSelector(getErrorList);
    const PointList = useSelector(getPointList);
    const MappedErrors = useSelector(getMapAuditFormErrorsList);

    const { auth } = useAuth()
    const dispatch = useDispatch();
    // Initialize state to store form values
    // Initialize state to store form values
    const initialState = {
        mappingId: Environment.defaultValue,
        errorId: Environment.defaultValue,
        errorCategoryId: Environment.defaultValue,
        subErrorCategoryId: Environment.defaultValue,
        formId: Environment.defaultValue,
        points: 0,
        createdBy: Environment.defaultValue,
        modifiedBy: Environment.defaultValue
    }
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        setFormData({
            ...formData,
            mappingId: props?.mappingId ? props?.mappingId : Environment.defaultValue,
            errorCategoryId: props?.selectedCategory,
            subErrorCategoryId: props?.selectedSubCategory,
            formId: props?.data?.formId,
            createdBy: auth.id,
            modifiedBy: auth.id


        })
    }, [props])
    const selectErrorOptions = (ErrorList || [])
    .filter(error => {
        // Ensure the error has the correct status
        if (error.status !== '5e03965f-c410-46b3-8713-b5058348ab02') {
            return false;
        }
        const mappedCategory = (MappedErrors || []).find(mapped => mapped.errorCategoryId === props.selectedCategory);
        // Find the selected sub-error category in MappedErrors
        const mappedSubCategory = (mappedCategory?.subErrorCategory || []).find(
            subError => subError.subErrorCatId === formData.subErrorCategoryId
        );

        // If no mapped sub-error category is found, allow all error types
        if (!mappedSubCategory) {
            return true;
        }

        // Extract all error types for the selected sub-error category
        const existingErrorIds = mappedSubCategory.errorType?.map(err => err.errorId) || [];

        // If this error type is already mapped to the selected sub-error category, exclude it
        return !existingErrorIds.includes(error.id);
    })
    .map(data => ({
        value: data.id,
        label: data.errorName
    }));


    const selectPointOption = PointList?.map((data) => ({
        value: data.id,
        label: data.pointValue
    }))
    // Handle Point selection change
    const handlePointChange = (selectedOption) => {
        setSelectedPoint(selectedOption)
        setFormData({
            ...formData,
            points: selectedOption.label
        });
    };
    const handleChange = (selectedOption) => {
        setSelectedError(selectedOption)
        setFormData({
            ...formData,
            errorId: selectedOption.value
        });
    };
    const handleCreateError = (e) => {
        e.preventDefault();
        dispatch(MapAuditFormErrorType([formData]));
        setFormData(initialState)
        props.closeFunction();
        setSelectedError(null);
        setSelectedPoint(null);
    };

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add Error</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col sm={12}>
                            <div className="mb-3">
                                <Form.Label>Error</Form.Label>
                                <Select
                                    options={selectErrorOptions}
                                    isSearchable={true}
                                    value={selectedError}
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>

                        <Col md={5}>
                            <div className="mb-3">
                                <Form.Label>Point</Form.Label>
                                <Select
                                    options={selectPointOption}
                                    value={selectedPoint} // Bind selected value
                                    onChange={handlePointChange} // Update selected value on change
                                    getOptionLabel={(e) => `${e.label}`} // Display label and status in dropdown
                                />
                            </div>
                        </Col>
                    </Row>

                </Offcanvas.Body>
                <div className="offcanvas-footer justify-content-start">
                    <Button type="button" variant="primary" onClick={handleCreateError}>
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Add Error</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}

import React from "react";
import DataTable from "react-data-table-component";
import closeAuditlist from "../../../Json/Myaudit/close-audit.json";
import { Card, Form, Badge, Button } from "react-bootstrap";

export default function CloseAuditComponent() {
    const columns = [
        {
            name: "Case ID",
            selector: (row) => row.lmsCaseId,
        },
        {
            name: "Agent Name",
            selector: (row) => (
                <h6 >{row.agentName}</h6>
            ),
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => (
                <h6 >{row.agentEmail}</h6>
            ),
            sortable: true,
        },
        {
            name: "Auditor Name",
            selector: (row) => (
                <h6>{row.auditorName}</h6>
            ),
            sortable: true,
        },
        {
            name: "Agent Name",
            selector: (row) => row.agent_name,
        },
        {
            name: "Process",
            selector: (row) => row.process,
        },
        {
            name: "Date",
            selector: (row) => row.date,
        },
        {
            name: "status",
            selector: (row) => (
                <div>
                    <Badge bg={`${row.status === "Open" ? "success" : "danger"}`} pill>{row.status}</Badge>
                </div>
            ),
        },
        {
            name: "Action",
            selector: () => (
                <div className="d-flex">
                    <Button variant="dark" className="btn-icon"><i className="ri-eye-line"></i></Button>
                </div>
            ),
        },
    ];

    return (
        <React.Fragment>
        <div className="d-md-flex align-items-center justify-content-between mb-3">
            <div>
                <h4 className="main-title fs-16 mb-0">Close Audits</h4>
            </div>
        </div>

        <Card className="card-one">
            <Card.Header className="align-items-center justify-content-between">
                <div>
                    <h4 className="main-title fs-14 mb-0">List Of Close audit</h4>
                </div>
                <div className="custom-dropdown-wrapper position-relative">
                    <div className="custom-drop-down z-index-2 wt-300">
                        <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                        <Form.Control type="text" placeholder="Search..." />
                    </div>
                </div>
            </Card.Header>

            <Card.Body>
                <div className="custom-common-table">
                    <DataTable
                        columns={columns}
                        data={closeAuditlist}
                        fixedHeader
                        search={true}
                        highlightOnHover
                        pagination
                    ></DataTable>
                </div>
            </Card.Body>
        </Card>
    </React.Fragment>
    )
}
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Card, Col, Form, Row, Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDataSourceList, getHeaderList, getMapProjectColumnList, getProjectDetails } from "../../../application/selector/indexSelector";
import useAuth from "../../../hooks/useAuth";
import { MapProjectColumn, UnMapProjectColumn, UpdateProject } from "../../../application/action/projectAction";
import Environment from "../../../infrastructure/core/Environment";

export default function ProjectData(props) {
    const dispatch = useDispatch();
    const columnsList = useSelector(getHeaderList);
    const mappedColumnList = useSelector(getMapProjectColumnList);
    const datasourceList = useSelector(getDataSourceList)
    const projectData = useSelector(getProjectDetails)
    const [mappedColumns, setMappedColumns] = useState([]);
    const { auth } = useAuth();
    useEffect(() => {
        setMappedColumns(mappedColumnList);
    }, [mappedColumnList]);


    // Filter out already mapped columns
    const availableColumns = columnsList?.filter(
        col => !mappedColumns?.some(mapped => mapped.colId === col.id)
    );

    const associatedDataOption = availableColumns?.map((col) => ({
        value: col.id,           // Using col.id for the value
        label: col.headerValue   // Assuming headerValue is a property of col
    }));
    const [selectedColumns, setSelectedColumns] = useState([]);

    const handleAddColumns = () => {
        // Create a new array with the selected columns to be added
        const newColumns = selectedColumns.map((option) => ({
            colId: option.value,
            colName: option.label,
            colValue:columnsList?.find(data=> data.headerValue===option.label)?.headerId ,
            status: Environment.defaultValue, // Assign default status
        }));
        // Combine previous mapped columns and new columns while avoiding duplicates
        setMappedColumns((prevMappedColumns) => {
            const combinedColumns = [
                ...prevMappedColumns,
                ...newColumns.filter(
                    (newCol) => !prevMappedColumns.some((col) => col.colId === newCol.colId)
                ),
            ];
            return combinedColumns;
        });
    
        // Clear the selected columns after adding
        setSelectedColumns([]);
    };
    
    // Remove column from the mapped columns list
    const removeCol = (item) => {
        setMappedColumns(mappedColumns.filter((team) => team.colId !== item.colId));
        const data={
            id:item.id,
            projectId:projectData?.id
        }
        dispatch(UnMapProjectColumn(data))
       
        
    };
    const handleColChange = (selectedOptions) => {
        setSelectedColumns(selectedOptions); // Handle null/undefined cases
    };
    

    const initialState = {
        Id:Environment.defaultValue,
        projectName: "",
        projectDescription: "",
        teamId: null,
        sourceId: null,
        empId: "",
        createdBy: auth.id,
        modifiedBy: auth.id
    };
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if (projectData) {
            setFormData({
                ...formData,
                Id:projectData?.id,
                projectName:projectData?.projectName,
                projectDescription: projectData?.projectDescription,
                sourceId: projectData?.sourceId,
                teamId:projectData?.teamId
            });
        }
    }, [projectData]);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Prepare the payload for newly mapped columns
        const newColumnsPayload = mappedColumns?.filter(data=> data.id ===undefined).map((col) => ({
            colId: col.colId,
            colName: col.colValue,
            // colValue:col.colValue,
            createdBy: auth.id,
            modifiedBy: auth.id,
        }));
        const payload = {
            ...formData,
            status: "da973bc9-b30e-447b-aa42-14dc7e0cdb01",
            columns: newColumnsPayload, // Only include newly mapped columns
        };
        // Dispatch the update action with only the new columns
        dispatch(UpdateProject(payload));
        props?.closeFunction()
    };
    
    return (
        <React.Fragment>
            <Card className="card-one">
                <Card.Body>
                    <Row>
                        <Col md={8}>
                            <div className="mb-4">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Project Name"
                                    name="projectName"
                                    value={formData.projectName}
                                    onChange={handleInputChange} // Handle input changes
                                    disabled
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <div className="mb-4">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    placeholder="Enter Description"
                                    name="projectDescription"
                                    value={formData.projectDescription}
                                    onChange={handleInputChange} // Handle input changes
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md={12}>
                            <div>
                                <Form.Label>Data Source: </Form.Label>
                                <Form.Select className="wt-400" name="sourceId" value={formData.sourceId} onChange={handleInputChange}>
                                    <option >Select Data Source</option>
                                    {datasourceList?.map((source) => (
                                        <option key={source.id} value={source.id}>
                                            {source.datasourceName}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-4">
                                <Form.Label>Associated Data Columns</Form.Label>
                                <div className="d-flex gap-2 mb-4">
                                    <Select
                                        className="wt-400"
                                        options={associatedDataOption}  // Dropdown options
                                        value={selectedColumns}            // Bind to selected teams state
                                        onChange={handleColChange}      // Update state on selection change
                                        isMulti={true}                  // Enable multi-select
                                        isSearchable={true}             // Enables searching in the dropdown
                                        placeholder="Select Columns"      // Updated placeholder
                                    />
                                    <Button type="button" variant="dark" onClick={handleAddColumns}>Include</Button>
                                </div>
                                <div>
                                <h6 className="fs-14 mb-2">Defaults fields</h6>
                                <div className="d-flex gap-2 align-items-center flex-wrap mb-3">
                                    {mappedColumns?.filter(item => item.status === "da973bc9-b30e-447b-aa42-14dc7e0cdb01").map((item, index) => (
                                        <Badge className="fs-14" bg="secondary" pill key={index}>
                                            <span>{item.colName}</span>
                                        </Badge>
                                    ))}
                                </div>

                                <div className="d-flex gap-2 align-items-center flex-wrap">
                                    {mappedColumns?.length === 0 ? (
                                        <h6 className="fs-14 text-center">No columns mapped</h6>
                                    ) : (
                                        mappedColumns?.filter(item => item.status !== "da973bc9-b30e-447b-aa42-14dc7e0cdb01").map((item, index) => (
                                            <Badge bg="primary" className="fs-14" key={index} pill>
                                                <span>{item.colName}</span>
                                                <Link className="text-white align-middle ms-1" onClick={() => removeCol(item)}>
                                                    <i className="ri-close-circle-line"></i>
                                                </Link>
                                            </Badge>
                                        ))

                                    )}
                                </div>

                            </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
                
            <div className="offcanvas-footer justify-content-start">
                <Button
                    type="submit"
                    variant="primary"
                    className="fs-14 me-2 d-flex align-items-center"
                    onClick={handleSubmit}
                >
                    {/* <i className="ri-add-line fs-18 lh-1 align-middle"></i> */}
                   <span className="align-middle">Update Project</span>

                </Button>
            </div>
            </Card>
        </React.Fragment>
    );
}

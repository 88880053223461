import { useRef, useState, useEffect, React } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
//import logo from "../../assets/img/logo/main-logo.png";
import LoadingSpinner from "../_common/LoadingSpinner";
import { jwtDecode } from "jwt-decode";
import Environment from "../../infrastructure/core/Environment";
//import ResetPassword from "./resetPassword";
import loginBg from "../../assets/img/account/login-bg.png";

// const LOGIN_URL = "https://argateway.cxengine.net/api/Auth/login";
export default function LoginComponent() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading({
        isLoading: true,
        value: "login",
      });

      const response = await axios.post(
        Environment.AUTH_URL + "login",
        JSON.stringify({ username: user, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      let roleType;
      // Decode the JWT token to extract roles
      const decodedToken = jwtDecode(response.data.token);
      let roles =
        decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      // Ensure roles is an array
      if (typeof roles === "string") {
        roles = [roles];
        roleType = roles
      }
      const data = {
        user,
        pwd,
        roleType,
        roles, // Set the roles from the decoded token
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        id: response.data.id,
        isResetDefaultPass: response.data.isResetDefaultPass,
        name: response.data.name,
        email: response.data.email
      };
      sessionStorage.setItem("auth", JSON.stringify(data));
      setAuth({
        user,
        pwd,
        roleType,
        roles, // Set the roles from the decoded token
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        id: response.data.id,
        isResetDefaultPass: response.data.isResetDefaultPass,
        name: response.data.name,
        email: response.data.email
      });
      if (!response.data.isResetDefaultPass) {
        navigate("/account/reset-password");
      } else {
        navigate("/dashboard/manage");
      }
    } catch (err) {
      if (!err?.response) {
        console.log(err);
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
      setLoading({ isLoading: false, value: "login" });
    } finally {
      setLoading({ isLoading: false, value: "login" });
    }
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Card.Title>Login</Card.Title>
              <Card.Text className="text-dark">
                Welcome back! Please login to continue.
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <Form>
                <div className="mb-4">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Username"
                    name="username"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                  />
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">Password</Form.Label>
                  <Form.Control
                    name="password"
                    placeholder="Enter your password"
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                  />
                </div>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <Button type="button" className="btn-sign"
                  disabled={loading.isLoading && loading?.value === "login"}
                  onClick={handleSubmit}
                >
                  {loading.isLoading && loading?.value === "login" ? (
                    <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
                  ) : (
                    "Let me in"
                  )}
                </Button>

                <div className="text-end mt-1">
                  <Link to="/account/forgot-password" className="float-end">
                    Forgot password?
                  </Link>
                </div>
              </Form>
            </Card.Body>
            <Card.Footer>
              {/* <Link to="/account/signup">Create an Account</Link> */}
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block position-relative" style={{
          backgroundImage: `url(${loginBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}>
          <div class="login-content ms-12"><h2 class="text-white mb-2">Welcome to NAS</h2>
          <h1 class="text-white">Natera Audit System</h1></div>
        </Col>
      </Row>
    </div>
  );
}

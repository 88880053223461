import React, { useEffect } from "react";
import Header from "../../../layouts/Header";
import { Badge, Card, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import errorDetailData from "../../../Json/AuditForm/audit-form-details.json";

export default function AuditErrorDetails() {

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app manage-item-page p-3 p-lg-4">
                <Row className="mb-4 align-items-center">
                    <Col>
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item">
                                    <Link to="/auditform/manage">Audit Form</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Details</li>
                            </ol>
                            <h4 className="main-title mb-0">Error Details</h4>
                        </div>
                    </Col>
                </Row>

                <Row className="g-3">
                    <Col sm={12}>
                        <Card className="card-one h-auto">
                            <Card.Body>
                                <div className="error-category-details-wrapper">
                                    <h2 className="fs-16 fw-semibold mb-1">Deepak</h2>
                                    <p className="fs-14px">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>


                                <Row className="g-3 align-items-center flex-100 mt-3">
                                    <Col xl={3} md={3} xs={6}>
                                        <h5 className="fs-12 text-dark mb-1">Created On</h5>
                                        <h3 className="fs-14 text-dark fw-bold">Aamir Sayyed</h3>
                                    </Col>

                                    <Col xl={3} md={3} xs={6}>
                                        <h5 className="fs-12 text-dark mb-1">Created By</h5>
                                        <h3 className="fs-14 text-dark fw-bold">2025-01-03</h3>
                                    </Col>

                                    <Col xl={3} md={3} xs={6}>
                                        <h5 className="fs-12 text-dark mb-1">Total Sub Error</h5>
                                        <Badge bg="danger" pill className="fw-bold">
                                            {errorDetailData?.reduce((acc, curr) =>
                                                acc + (curr.error_types?.length || 0), 0
                                            ) || 0}
                                        </Badge>
                                    </Col>

                                    <Col xl={3} md={3} xs={6}>
                                        <h5 className="fs-12 text-dark mb-1">Total Error Type</h5>
                                        <Badge bg="danger" pill className="fw-bold">
                                            {errorDetailData?.reduce((acc, curr) =>
                                                acc + (curr.error_types?.reduce((subAcc, subCurr) =>
                                                    subAcc + (subCurr.errors?.length || 0), 0
                                                ) || 0), 0
                                            ) || 0}
                                        </Badge>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>


                    <Col sm={12}>
                        {errorDetailData?.map((item, index) => (
                            <Card className="card-one h-auto mb-3" key={index}>
                                <Card.Header>{item.error_name}</Card.Header>
                                <Card.Body>
                                    {item.error_types?.map((subError) => (
                                        <Card className="card-one h-auto mb-3 border">
                                            <Card.Header className="bg-light">{subError.type}</Card.Header>
                                            <Card.Body>
                                                <Table className="mb-0" responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>Error</th>
                                                            <th className="wt-100">Points</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {subError.errors?.map((error) => (
                                                            <tr>
                                                                <td>{error.error}</td>
                                                                <td>
                                                                    <Badge bg="primary" pill>{error.point}</Badge>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </Card.Body>
                            </Card>
                        ))}
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
};
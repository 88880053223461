import { GET_ALL_HEADER_SUCCESS, GET_DATASET_LIST_SUCCESS, GET_DATASOURCE_LIST_SUCCESS, GET_INVENTORY_DATA_LIST_BYID_SUCCESS, GET_INVENTORY_LIST_SUCCESS } from "../action/inventoryAction";

const initialState = {
    InventoryList: undefined,
    DataSourceList:undefined,
    DataSetList:undefined,
    InventoryDataList:undefined,
    HeaderList:undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVENTORY_LIST_SUCCESS:
            return { ...state, InventoryList: action.payload.data, error: null };
        case GET_DATASOURCE_LIST_SUCCESS:
            return { ...state,DataSourceList: action.payload.data, error: null };
        case GET_DATASET_LIST_SUCCESS:
            return { ...state,DataSetList: action.payload.data, error: null };
        case GET_INVENTORY_DATA_LIST_BYID_SUCCESS:
            return { ...state,InventoryDataList: action.payload.data, error: null };
        case GET_ALL_HEADER_SUCCESS:
            return { ...state,HeaderList: action.payload.data, error: null };
        default:
            return state;
    }
}

export default reducer;
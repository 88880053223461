export const GET_TEAMS_LIST = 'GET_TEAMS_LIST';
export const GET_TEAMS_LIST_SUCCESS = 'GET_TEAMS_LIST_SUCCESS';
export const UPLOAD_TEAM = 'UPLOAD_TEAM';
export const TeamsList = (data) => ({
    type: GET_TEAMS_LIST,
    payload:data
});
export const TeamsListSuccess = (data) => ({
    type: GET_TEAMS_LIST_SUCCESS,
    payload:data
});
export const UploadTeams = (data) => ({
    type: UPLOAD_TEAM,
    payload:data
});
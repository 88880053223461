import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Table } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { UploadInventory } from "../../application/action/inventoryAction";
import LoadingSpinner from "../_common/LoadingSpinner";
import { getLoading, getOperationId, getProgressData } from "../../application/selector/indexSelector";
import { OperationSuccess, pollProgress, setProgress, startOperation } from "../../application/action/loaderAction";
import ProgressBar from "../_common/PercentageLoader";

export default function InventoryExcelView({ data, formValue, closeFunction, show, closeuploadModal, setNullForm }) {
    const [previewData, setPreviewData] = useState([]);
    const { auth } = useAuth();
    const dispatch = useDispatch()
    const loading = useSelector(getLoading)
    const progress = useSelector(getProgressData);
    const operationId = useSelector(getOperationId);
    useEffect(() => {
        if (operationId && progress !== 100) {
            const interval = setInterval(async () => {
                dispatch(pollProgress(operationId)); // Poll for progress updates
            }, 1000); // Poll every second

            return () => clearInterval(interval); // Cleanup interval on unmount
        }
    }, [operationId, progress]);

    useEffect(() => {
        if (operationId !== undefined && formValue.file!== null) {
            const formData = new FormData
            formData.append('addedBy', auth.id)
            formData.append('Description', formValue.Description)
            formData.append('DataSourceId', formValue.DataSourceId)
            formData.append('operationId', operationId)
            formData.append("file", formValue.file);
            dispatch(UploadInventory(formData))
        }
    }, [operationId]);

    useEffect(() => {
        if (progress >= 100) {
            dispatch(setProgress(undefined))
            dispatch(OperationSuccess(undefined))
        }
    }, [progress]);

    useEffect(() => {
        if (Array.isArray(data)) {
            setPreviewData(data);
        } else {
            setPreviewData([]); // Reset to an empty array if data is invalid
        }
    }, [data]);
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(startOperation);

    }
    useEffect(() => {
        if (loading.value == 'inventoryUpload' && !loading.loading) {
            closeuploadModal()
            closeFunction()
            setNullForm()
            
        }
    }, [loading])
    return (
        <React.Fragment>
            <Offcanvas show={show} onHide={closeFunction} placement="end" className="w-80">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Inventory Excel View</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Table className="mb-0">
                        <thead>
                            <tr>
                                {previewData[0]?.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {previewData.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-end gap-2">
                {/* <div className="wt-250"> */}
                        {progress > 0 && (
                            <>
                                <span>File Upload in Progress: </span>
                                <ProgressBar progress={progress} />
                            </>
                        )}
                    {/* </div> */}
                    <Button
                        type="button"
                        variant="secondary"
                        className="fs-14 me-2 d-flex align-items-center"
                        onClick={() => closeFunction()}
                    >
                        <span className="align-middle">Clear</span>
                    </Button>
                    <Button type="button" variant="dark" className="fs-14 me-2 d-flex align-items-center" onClick={handleSubmit}>
                        <i className="ri-add-line fs-16 lh-1 align-middle me-1"></i>
                        {loading.loading && loading?.value === "inventoryUpload" ? (
                            <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
                        ) : (
                            "Submit"
                        )}
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
};
import React, { useEffect } from 'react'
import { useRef } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap'
import LoadingBar from 'react-top-loading-bar'
import ProductivityBridge_Template from '../../../assets/templates/ProductivityBridge_Template.xlsx'
import ErrorCategory_Template from '../../../assets/templates/ErrorCategory_Template.xlsx'
import Inventory_Template from '../../../assets/templates/Inventory_Template.xlsx'
import ErrorType_Template from '../../../assets/templates/ErrorType_Template.xlsx'
import SubErrorCategory_Template from '../../../assets/templates/SubErrorCategory_Template.xlsx'
import Team_Template from '../../../assets/templates/Team_Template.xlsx'

function TemplateManage() {
  // PROGRESS TOP LOADER
  const loadingBarRef = useRef(null);
  useEffect(() => {
    if (loadingBarRef.current) {
      // Start the loading bar
      loadingBarRef.current.continuousStart();

      // Simulate a load completion or manage dynamically
      const timer = setTimeout(() => {
        loadingBarRef.current.complete();
      }, 3000); // Adjust timing as needed

      // Cleanup timer
      return () => clearTimeout(timer);
    }
  }, []);
  return (
    <React.Fragment>
      <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="fs-16 mb-1">Templates</h4>
          <h6 className="fs-14 text-black-50">Templates for all the file upload actions</h6>
        </div>
      </div>

      <Row className="row-cols-auto g-3 g-xl-4 pt-2">

        <Col lg={3} md={3} sm={6} >
          <Card className="card-one">
            <Card.Body>
              <div>
                <h4 className="fs-16 mb-1 pb-2">Productivity Bridge</h4>
                <a
                  href={ProductivityBridge_Template}
                  download="ProductivityBridge_Template.xlsx"
                >
                  <Button variant="outline-primary">
                    {" "}
                    <i className="ri-download-line fs-16 lh-1"> </i>
                    Productivity Bridge Template
                  </Button>
                </a>

              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3} md={3} sm={6} >
          <Card className="card-one">
            <Card.Body>
              <div>
                <h4 className="fs-16 mb-1 pb-2">Error Category</h4>
                <a
                  href={ErrorCategory_Template}
                  download="ErrorCategory_Template.xlsx"
                >
                  <Button variant="outline-primary">
                    {" "}
                    <i className="ri-download-line fs-16 lh-1"></i> Error Category
                    Template
                  </Button>
                </a>

              </div>
            </Card.Body>
          </Card>
        </Col>
       
        <Col lg={3} md={3} sm={6} >
          <Card className="card-one">
            <Card.Body>
              <div>
                <h4 className="fs-16 mb-1 pb-2">Error Sub Category</h4>
                <a
                  href={SubErrorCategory_Template}
                  download="ErrorSubCategory_Template.xlsx"
                >
                  <Button variant="outline-primary">
                    {" "}
                    <i className="ri-download-line fs-16 lh-1"></i> Error Sub Category
                    Template
                  </Button>
                </a>

              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3} md={3} sm={6} >
          <Card className="card-one">
            <Card.Body>
              <div>
                <h4 className="fs-16 mb-1 pb-2">Error Type</h4>
                <a
                  href={ErrorType_Template}
                  download="ErrorType_Template.xlsx"
                >
                  <Button variant="outline-primary">
                    {" "}
                    <i className="ri-download-line fs-16 lh-1"></i> Error Type
                    Template
                  </Button>
                </a>

              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3} md={3} sm={6} >
          <Card className="card-one">
            <Card.Body>
              <div>
                <h4 className="fs-16 mb-1 pb-2">Inventory</h4>
                <a
                  href={Inventory_Template}
                  download="Inventory_Template.xlsx"
                >
                  <Button variant="outline-primary">
                    {" "}
                    <i className="ri-download-line fs-16 lh-1"></i> Download
                    Template
                  </Button>
                </a>

              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={3} md={3} sm={6} >
          <Card className="card-one">
            <Card.Body>
              <div>
                <h4 className="fs-16 mb-1 pb-2">Teams</h4>
                <a
                  href={Team_Template}
                  download="Team_Template.xlsx"
                >
                  <Button variant="outline-primary">
                    {" "}
                    <i className="ri-download-line fs-16 lh-1"></i> Download
                    Template
                  </Button>
                </a>

              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default TemplateManage
// validationUtils.js

import Environment from "./Environment";

// Function to check if a value is required (i.e., not empty or only whitespace)
export const validateRequired = (value) => {
  // Convert the value to a string before calling trim()
  const trimmedValue = String(value).trim();
  return trimmedValue !== ""; // Check if the trimmed value is not empty
};
export const validateRequiredDropdown = (value) => {
  // Convert the value to a string before calling trim()
  let trimmedValue = value;
  return trimmedValue !== Environment.defaultValue && trimmedValue !== null; // Check if the trimmed value is not empty
};
export const validateRequiredNumberDropdown = (value) => {
  // Convert the value to a string before calling trim()
  let trimmedValue = value;
  return trimmedValue !== 0 && trimmedValue !== null; // Check if the trimmed value is not empty
};
// Function to restrict input to numbers and limit to 10 characters
export const restrictOnlyNumber = (event) => {
  const { key, target } = event;
  const trimmedValue = target.value.trim();
  if (key === "Backspace" || key === "Delete") {
    return;
  }
  if (key !== " ") {
    // Check if the pressed key is a number and the current value length is less than 10
    if (!isNaN(key) && trimmedValue.length < 10) {
      return; // Allow typing the number
    }
  }
  event.preventDefault(); // Prevent the default behavior (typing the number)
};
export const validateEmail = (email) => {
  // Regular expression for email validation
  const emailRegex =
    /^[a-zA-Z0-9](?:(?!.*[.@]{2})[a-zA-Z0-9.]+)?@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/;
  const regex = /[^\w.@\b]/g;
  if (regex.test(email)) {
    return;
  }
  return emailRegex.test(email);
};
export const restrictConsecutiveSpace = (e) => {
  const { value } = e.target;
  if (e.keyCode === 32 && (value.trim() === "" || value.endsWith(" "))) {
    e.preventDefault();
    return;
  }
};
export const restrictSpace = (e) => {
  if (e.keyCode === 32) {
    e.preventDefault();
    return;
  }
};
export const restrictAllSpecialCharacters = (e) => {
  const pattern = /^[a-zA-Z0-9\s]*$/;
  if (!pattern.test(e.key)) {
    e.preventDefault();
    return;
  }
};
export const restrictAllNumbers = (e) => {
  const pattern = /^[^0-9]*$/;
  if (!pattern.test(e.key)) {
    e.preventDefault();
    return;
  }
};
export const restrictEmailCharacters = (e) => {
  const currentValue = e.target.value;
  const keyPressed = e.key;

  // Prevent input if the key pressed is not a letter, digit, dot, or @ symbol
  if (!/[a-zA-Z0-9.@]/.test(keyPressed)) {
    e.preventDefault();
    return;
  }
  // If the key pressed is a dot
  if (keyPressed === ".") {
    // Check if the current value already contains a dot at the end, or if the last character is '@'
    if (/\.{1,}$/.test(currentValue) || currentValue.endsWith("@")) {
      e.preventDefault();
      return;
    }
  }
  // If the key pressed is @ symbol
  if (keyPressed === "@") {
    // Check if @ symbol already exists or if it's the first character, or if the previous character is a dot
    if (
      currentValue.includes("@") ||
      currentValue.length === 0 ||
      currentValue.endsWith(".")
    ) {
      e.preventDefault();
      return;
    }
  }
};

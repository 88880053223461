
import Environment from '../../core/Environment';
import jwtInterceptor from '../../core/helpers/jwtInterceptor';

const UsersMappingList = async () => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.USER_URL}GetUserMappingList`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
}
const AddUser = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.USER_URL}createUser`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
}
const DeleteUser = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.USER_URL}deleteUser/${data}`,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
}
const UpdateUser = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.USER_URL}UpdateUser`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
}
const AddUserGroup = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(`${Environment.USER_URL}AddUserGroup`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
}
  const UploadBridgeData = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.post(`${Environment.USER_URL}UploadUserProductivityBridge`,data,{
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const GetAllUserByRoleId = async (roleId) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.USER_URL}GetUserByRoleId/${roleId}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const GetAllRoles = async () => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.USER_URL}userRoles`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const GetAllUsers = async () => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.USER_URL}allUsers`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const GetAllUserGroups = async () => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.USER_URL}GetAllUserGroup`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
const GetUserDetail = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.USER_URL}details/${data}`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};

export default {
    UsersMappingList,UploadBridgeData,GetAllUserByRoleId,GetAllRoles,AddUser,GetAllUserGroups,AddUserGroup, GetAllUsers, GetUserDetail, DeleteUser, UpdateUser
  }
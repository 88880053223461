import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Modal, Form, Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Environment from "../../../../infrastructure/core/Environment";
import axios from "../../../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { getAuditDetails, getCaseFilesList } from "../../../../application/selector/indexSelector";
import { AddChangeLog, UploadCaseDocument } from "../../../../application/action/auditAction";
import excelIcon from "../../../../assets/img/icons/excel.png"
import pdfIcon from "../../../../assets/img/icons/pdf.png"
import screenshortIcon from "../../../../assets/img/icons/screenshort.png";
import useAuth from "../../../../hooks/useAuth";
import LoadingSpinner from "../../../_common/LoadingSpinner";
import ToastifyService from "../../../_common/ToastifyService";



export default function DocumentUpload(props) {
  const { auditId } = useParams();
  const { auth } = useAuth()
  const dispatch = useDispatch()
  const AuditDetailData = useSelector(getAuditDetails)
  const CaseFilesList = useSelector(getCaseFilesList)
  const [imageBase64, setImageBase64] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [isSelecting, setIsSelecting] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selection, setSelection] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [scaleFactor, setScaleFactor] = useState({ x: 1, y: 1 });
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });

  const fileRef = useRef(null);
  const reset = () => {
    fileRef.current.value = "";
  };
  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return pdfIcon;
      case "xlsx":
      case "xls":
        return excelIcon;
      case "png":
      case "jpg":
      case "jpeg":
        return screenshortIcon;
      default:
        return screenshortIcon; // Default icon for unsupported types
    }
  };
  // const handleDeleteFile = async (id, fileData) => {
  //   try {
  //     setLoading({
  //       isLoading: true,
  //       value: `Delete-${id}`,
  //     });
  //     const fileNames = [
  //       `${fileData.id}.${fileData.fileName.split(".").pop()}`,
  //     ];
  //     const location = `CaseDocs/${props?.taskDetails?.current?.lims_casefile_id}`;
  //     if (!location) {
  //       throw new Error("Location is invalid or missing.");
  //     }
  //     const formData = new FormData();
  //     formData.append("Location", location);
  //     formData.append("FileNames", JSON.stringify(fileNames));
  //     await axios.post(`${COMMON_SERVICE}File/Delete/ARWorkflow`, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     const response = await axiosPrivate.post(
  //       `Task/delete-case-doc/${props?.taskId}/${fileData.fileName}/${id}/${props?.taskDetails?.current?.id}`
  //     );
  //     props.taskDetails.current.casedetailslogs =
  //       response?.data?.result.caseDetailsLog;
  //     setDocumentList(response?.data?.result.caseDocumentUpload);
  //     caseDocId.current = response?.data?.result?.generatedId;
  //     ToastifyService.success("Documents Deleted successfully");
  //   } catch (error) {
  //     console.error("Error while deleting the files:", error);
  //     ToastifyService.error("Failed to delete the documents");
  //   } finally {
  //     setLoading({
  //       isLoading: false,
  //       value: `Delete-${id}`,
  //     });
  //   }
  // };
  const handleDownloadFile = async (fileData) => {
    try {
      const data = new FormData();
      data.append("ProjectName", `AR_AUDIT_TOOL`);
      data.append("Location", `AR_AUDIT_TOOL/${fileData.fileId}`);
      data.append("FileName", fileData.fileName);

      // Make the POST request
      const response = await axios.post(
        `${Environment.COMMON_SERVICE}Download/AR_AUDIT_TOOL`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob", // Ensure binary data is correctly handled
        }
      );

      // Create a Blob from the response and trigger the download
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileData.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the object URL
      window.URL.revokeObjectURL(downloadUrl);

    } catch (error) {
      ToastifyService.error("Something Went wrong! ");
      console.error("Error downloading file:", error);
    }
  };


  // const handleOpenFile = async (fileData) => {
  //   try {
  //     setLoading({
  //       isLoading: true,
  //       value: `OpenFile-${fileData.id}`,
  //     });
  //     const extension = fileData.fileName.split(".").pop();
  //     const response = await axios.get(
  //       `${Environment.COMMON_SERVICE}File/Open/ARWorkflow`,
  //       {
  //         params: {
  //           Location: `CaseDocs/${props?.taskDetails?.current?.lims_casefile_id}`,
  //           FileName: `${fileData.id}.${extension}`,
  //         },
  //         responseType: "blob",
  //       }
  //     );
  //     const formDataObject = {
  //       CaseId: props?.taskDetails?.current?.id,
  //       VisitId: props?.taskDetails?.current?.visitfid,
  //       LogSummary: `Opened file '${fileData.fileName}'`,
  //     };
  //     const caseLogResponse = await axiosPrivate.post(
  //       `Task/add-case-logs`,
  //       formDataObject
  //     );
  //     props.taskDetails.current.casedetailslogs = caseLogResponse?.data?.result;
  //     const blob = new Blob([response.data], {
  //       type: response.headers["content-type"],
  //     });
  //     const fileUrl = window.URL.createObjectURL(blob);
  //     window.open(fileUrl, "_blank");
  //     setTimeout(() => window.URL.revokeObjectURL(fileUrl), 60000);
  //   } catch (error) {
  //     console.error("Error opening file:", error);
  //     ToastifyService.error("Failed to open the document");
  //   } finally {
  //     setLoading({
  //       isLoading: false,
  //       value: `OpenFile-${fileData.id}`,
  //     });
  //   }
  // };
  const handleFileChange = (e) => {
    setCroppedImage("");
    setImageBase64("");
    const file = e.target.files[0]; // Get the selected file
    setSelectedFile(file); // Store it in state
  };
  const handleUploadDoc = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    const fileInput = fileRef.current;
    const file = fileInput.files[0];
  
    // Determine file name based on file or imageBase64/croppedImage
    const FileName = file
      ? file.name === ""
        ? fileInput.value.split("\\").pop()
        : file.name
      : imageBase64 || croppedImage
      ? `${fileName || "screenshot"}.png` // Use "screenshot" as default if fileName is empty
      : `${fileName}.png`;  // Default to the original fileName if not a screenshot
  
    if (file) {
      formData.append("File", file);
    } else if (imageBase64 && !croppedImage) {
      const screenshotFile = base64ToFile(imageBase64, "screenshot.png");
      formData.append("File", screenshotFile);
    } else if (croppedImage) {
      const croppedFile = base64ToFile(croppedImage, "cropped_image.png");
      formData.append("File", croppedFile);
    }
  
    formData.append("Files", file); // 'Files' must match the backend parameter name
    formData.append("FileNames", FileName);
    formData.append("auditId", auditId);
    formData.append("caseId", AuditDetailData.caseId);
    formData.append("lmsCaseId", AuditDetailData.lmsCaseId);
    formData.append("addedBy", auth.id);
  
    dispatch(UploadCaseDocument(formData));
  
    const changeData = {
      mappingId: Environment.defaultValue,
      auditId: auditId,
      summary: `Uploaded Document: "${file?.name || "screenshot.png"}"`,
      updatedBy: auth.id,
      updatedByName: auth.user,
    };
    dispatch(AddChangeLog(changeData));
  };
  
  function base64ToFile(base64, filename) {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const closeModal = () => {
    props?.setShowUploadDocModal(false);
  };
  const captureScreenshot = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      const video = videoRef.current;
      video.srcObject = stream;
      video.onloadedmetadata = () => {
        video.play();
        video.onplaying = () => {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          stream.getTracks().forEach((track) => track.stop());
          const base64 = canvas.toDataURL("image/png");
          setImageBase64(base64);
        };
      };
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
  };
  const handleMouseDown = (e) => {
    setIsSelecting(true);
    setSelection({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      width: 0,
      height: 0,
    });
  };
  const handleMouseMove = (e) => {
    if (!isSelecting) return;
    setSelection((prev) => ({
      ...prev,
      width: e.nativeEvent.offsetX - prev.x,
      height: e.nativeEvent.offsetY - prev.y,
    }));
  };
  const handleMouseUp = () => {
    setIsSelecting(false);
    cropSelectedRegion();
  };
  const cropSelectedRegion = () => {
    const canvas = canvasRef.current;
    const x =
      Math.min(selection.x, selection.x + selection.width) * scaleFactor.x;
    const y =
      Math.min(selection.y, selection.y + selection.height) * scaleFactor.y;
    const width = Math.abs(selection.width) * scaleFactor.x;
    const height = Math.abs(selection.height) * scaleFactor.y;

    const croppedCanvas = document.createElement("canvas");
    croppedCanvas.width = width;
    croppedCanvas.height = height;
    const croppedCtx = croppedCanvas.getContext("2d");
    croppedCtx.drawImage(canvas, x, y, width, height, 0, 0, width, height);

    // Convert the cropped canvas to a base64 image
    const base64 = croppedCanvas.toDataURL("image/png");
    setCroppedImage(base64);
  };
  const handleImageLoad = (e) => {
    const img = e.target;
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    setScaleFactor({ x: scaleX, y: scaleY });
  };
  const isDocument = (file) => {
    const documentTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    return file && documentTypes.includes(file.type); // Check file type
  };

  const resetAllDocs = () => {
    setCroppedImage("");
    setImageBase64("");
    setIsSelecting(false);
    setFileName("");
    setSelectedFile(null);
    fileRef.current.value = "";
    setSelection({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    });
    setScaleFactor({ x: 1, y: 1 });
    reset();
  };
  return (
    <React.Fragment>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="fs-16 main-title mb-0">Uploaded Documents</h4>
        </div>
      </div>

      {CaseFilesList?.map(data => (
        <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
          <div className="d-flex gap-2">
            <div>
              <Image
                src={getFileIcon(data.fileName)} // Dynamically determine file icon
                alt="icon"
                width={30}
                height={30}
              />
            </div>
            <div>
              <h4 className="fs-14">{data.fileName}</h4>
              <h6 className="fs-12 text-black-50">By: {data.addedByName}</h6>
            </div>
          </div>

          <div>
            <Button variant="outline-dark" className="btn-icon" onClick={() => handleDownloadFile(data)}><i className="ri-download-2-line fs-18" ></i></Button>
          </div>
        </div>
      ))}
      <Modal
        centered
        show={props?.showUploadDocModal}
        onHide={() => {
          closeModal();
          resetAllDocs();
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-14">Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex ms-3">
            <div className="mb-2">
              <Form.Label>Choose a File</Form.Label>
              <Form.Control
                type="file"
                ref={fileRef}
                onChange={(e) => handleFileChange(e)}
              />
            </div>
            <div className="mb-1 ms-3">
              <Form.Label>Take a screenshot</Form.Label>

              <Button
                onClick={() => {
                  reset();
                  captureScreenshot();
                }}
                varient="primary"
                className="d-flex align-items-center"
                size="sm"
              >
                <i className="fs-18 ri-screenshot-2-line me-2"></i>
                <span className="fs-14">Capture Screenshot</span>
              </Button>
            </div>

            <div className="mb-1 ms-3">
              <Form.Label>Clear </Form.Label>
              <Button
                onClick={() => {
                  resetAllDocs();
                }}
                varient="primary"
                className="d-flex align-items-center"
                size="sm"
              >
                <i className="fs-18 ri-close-fill me-2"></i>
                <span className="fs-14">Clear</span>
              </Button>
            </div>
          </div>
          <div>
            <header className="App-header">
              <div style={{ position: "relative", marginTop: "20px" }}>
                {imageBase64 && (
                  <img
                    src={imageBase64}
                    alt="Screenshot"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      cursor: "crosshair",
                    }}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onLoad={handleImageLoad}
                  />
                )}
                {isSelecting && (
                  <div
                    style={{
                      position: "absolute",
                      left: selection.x,
                      top: selection.y,
                      width: selection.width,
                      height: selection.height,
                      border: "2px solid blue",
                      backgroundColor: "rgba(0, 0, 255, 0.2)",
                    }}
                  />
                )}
              </div>
              {croppedImage && (
                <div>
                  <h2>Cropped Image:</h2>
                  <img
                    src={croppedImage}
                    alt="Cropped Screenshot"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              )}
            </header>
            <video ref={videoRef} style={{ display: "none" }} />
            <canvas ref={canvasRef} style={{ display: "none" }} />
          </div>
          {imageBase64 && (
            <div className="mb-3 ms-3 mt-3 w-50">
              <Form.Label>Screenshot Name</Form.Label>
              <Form.Control
                type="text"
                name="FileName"
                placeholder="Enter Patient Name"
                onChange={(e) => setFileName(e.target.value)}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleUploadDoc}
            disabled={loading.isLoading && loading?.value === "UploadDoc"}
          >
            {loading.isLoading && loading?.value === "UploadDoc" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              "Upload"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Offcanvas, Form, Card, Button } from "react-bootstrap";
import listViewData from "../../../../../Json/QAAudit/view-list.json";
import { useNavigate } from "react-router-dom";
import TableShimmerLoader from "../../../../_common/ShimmerTable";
import { useDispatch, useSelector } from "react-redux";
import { getAuditFormListByProjId, getLoading, getOngoingCasesList, getPendingCasesList } from "../../../../../application/selector/indexSelector";
import Environment from "../../../../../infrastructure/core/Environment";
import useAuth from "../../../../../hooks/useAuth";
import jwtInterceptor from "../../../../../infrastructure/core/helpers/jwtInterceptor";
import { AddChangeLog } from "../../../../../application/action/auditAction";
import FormModal from "./select-form-modal";

export default function ViewlistModal(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { auth } = useAuth();
    const PendingCasesList = useSelector(getPendingCasesList);
    const { loading, value } = useSelector(getLoading);
    const [selectedAuditId, setSelectedAuditId] = useState('');
    const OngoingCasesList = useSelector(getOngoingCasesList);
    const AuditFormList = useSelector(getAuditFormListByProjId);
    const columns = [
        {
            name: "Case ID",
            selector: (row) => row.lmsCaseId,
            sortable: true,
        },
        // {
        //     name: "Agent Name",
        //     selector: (row) => row.agent_name,
        //     sortable: true,
        // },
        {
            name: "Agent Name",
            selector: (row) => row.agentName,
            sortable: true,
        },
        {
            name: "Team Name",
            selector: (row) => props?.selectedProject?.dataSetName,
            sortable: true,
        },
        {
            name: "Case Date",
            selector: (row) => '12 Dec 2022 08:30 PM',
            sortable: true,
        },
        {
            name: "Start Audit",
            selector: (row) => (
                <Button variant="primary" className="text-uppercase fs-12" onClick={() => goStartAudit(row)}>Start Audit</Button>
            ),
            sortable: true,
        },
    ];
    const [showFormListModal, setShowFormListModal] = useState(false);
    const handleFormListModal = (state) => {
        setShowFormListModal(state);
    };
    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = PendingCasesList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const goStartAudit = (data) => {
        
        if (PendingCasesList?.length > 0 && props?.selectedProject && !showFormListModal && AuditFormList && AuditFormList?.length !== 0) {
            const [caseToAudit] = PendingCasesList?.filter(
                pendingCase => !OngoingCasesList?.some(ongoingCase => ongoingCase.caseId === pendingCase.id) && pendingCase.status !== "2061156f-e154-4b21-80e6-641677224502"
            );
            if (props?.selectedProject?.form_Count === 1) {
                if (caseToAudit) {
                    caseSubmit(caseToAudit)
                }
            } else {
                setShowFormListModal(true)
            }
        }

    }
    const caseSubmit = async (caseToAudit) => {
        const auditData = {
            id: Environment.defaultValue,
            formId: AuditFormList[0]?.formId,
            projectId: props?.selectedProject.projectId,
            caseId: caseToAudit.id,
            datasetName: props?.selectedProject.dataSetName,
            agentId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            supervisorId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            finalScore: 0,
            auditScore: 0,
            ataScore: 0,
            rebuttalScore: 0,
            overAllRebuttal: "",
            overAllAuditorComment: "",
            assignedTo: auth.id,
            status: "2061156f-e154-4b21-80e6-641677224502",
            auditedBy: auth.id,
            ataBy: Environment.defaultValue,
            lmsCaseId: caseToAudit.lmsCaseId
        };
        try {
            const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}createAudit`, auditData, {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    //   authorization: `Bearer ${storedToken}`,
                },
            }
            );

            setSelectedAuditId(response.data)
            navigate(`/create/audit/${response.data}`);
            const changeData = {
                "mappingId": Environment.defaultValue,
                "auditId": response.data,
                "summary": `Opened Case`,
                "updatedBy": auth.id,
                "updatedByName": auth.user,
            }
            dispatch(AddChangeLog(changeData))

        } catch (err) {
            console.log(err);

        } finally {
            if (selectedAuditId) {
                navigate(`/create/audit/${selectedAuditId}`);
            }
        }

    }

    return (
        <React.Fragment>
            <FormModal show={showFormListModal} closeFunction={handleFormListModal} selectedProject={props?.selectedProject} />
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-60">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">
                        View List
                    </Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Card className="card-one">
                        <Card.Header className="align-items-center justify-content-end d-flex">
                            <div className="custom-dropdown-wrapper">
                                <div className="custom-drop-down z-index-2 wt-300">
                                    <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {filteredEmployees && filteredEmployees !== undefined && (
                                <DataTable
                                    columns={columns}
                                    data={filteredEmployees}
                                    fixedHeader
                                    search={true}
                                    highlightOnHover
                                    pagination
                                ></DataTable>
                            )}
                            {!loading && filteredEmployees === undefined && (
                                <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                            )}
                            {loading &&
                                value === "ViewList" && filteredEmployees === undefined && (
                                    <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                                )}
                        </Card.Body>
                    </Card>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}
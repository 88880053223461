import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Offcanvas, Form, Row, Col, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataSetList,
  getDataSourceList,
  getHeaderList,
  getLoading,
  getMapProjectColumnList,
  getOperationId,
  getProgressData,
  getProjectList,
  getTeamsList,
} from "../../../application/selector/indexSelector";
import useAuth from "../../../hooks/useAuth";
import Environment from "../../../infrastructure/core/Environment";
import {
  AddProject,
  MapProjectColumn,
} from "../../../application/action/projectAction";
import LoadingSpinner from "../../_common/LoadingSpinner";
import ProgressBar from "../../_common/PercentageLoader";
import {
  OperationSuccess,
  pollProgress,
  setProgress,
  startOperation,
} from "../../../application/action/loaderAction";
import {
  validateRequired,
  validateRequiredDropdown,
  validateRequiredNumberDropdown,
  restrictSpace,
  restrictConsecutiveSpace,
} from "../../../infrastructure/core/validationUtils";

export default function CreateProject(props) {
  const teamsList = useSelector(getTeamsList);
  const dataSetList = useSelector(getDataSetList);
  const columnsList = useSelector(getHeaderList);
  const datasourceList = useSelector(getDataSourceList);
  const mappedColumnList = useSelector(getMapProjectColumnList);
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const [mappedColumns, setMappedColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const progress = useSelector(getProgressData);
  const operationId = useSelector(getOperationId);
  // const ProjectsList = useSelector(getProjectList);
  const filteredList = teamsList?.filter((data) => {
    // Normalize teamName by removing special characters and converting to lowercase
    const normalizedTeamName = data.teamName
      .replace(/[-_ ]/g, "")
      .toLowerCase();
    // Check if no datasetName matches the normalized teamName
    return !dataSetList?.some(
      (set) =>
        set.datasetName.replace(/[-_ ]/g, "").toLowerCase() ===
        normalizedTeamName
    );
  });

  const handleAddColumns = () => {
    setMappedColumns((prevData) => {
      // Combine previous mapped columns with the new selections
      const updatedColumns = selectedColumns.map((option) => ({
        colId: option.value,
        colName: option.label,
        status: Environment.defaultValue,
      }));

      // Avoid duplicates by filtering out pre-existing items
      const uniqueColumns = [
        ...prevData.filter(
          (col) => !updatedColumns.find((opt) => opt.colId === col.colId)
        ),
        ...updatedColumns,
      ];

      return uniqueColumns;
    });
    setSelectedColumns([]); // Optionally reset the selected options
  };
  // Remove column from the mapped columns list
  const removeCol = (item) => {
    setMappedColumns(mappedColumns.filter((team) => team.colId !== item.colId));
  };
  useEffect(() => {
    setMappedColumns(
      columnsList
        ?.filter((col) => col.status === "da973bc9-b30e-447b-aa42-14dc7e0cdb01")
        .map((data) => ({
          colId: data.id,
          colName: data.headerValue,
          colHeader: data.headerId,
          status: "da973bc9-b30e-447b-aa42-14dc7e0cdb01",
        }))
    );
  }, [columnsList]);

  const handleColChange = (selectedOptions) => {
    setSelectedColumns(selectedOptions); // Store selected options
  };

  const { auth } = useAuth();
  const initialState = {
    projectName: "",
    projectDescription: "",
    teamId: Environment.defaultValue,
    sourceId: Environment.defaultValue,
    empId: "",
    createdBy: auth.id,
    modifiedBy: auth.id,
  };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const validateInput = () => {
    const newErrors = {};
    // Validate Charge Name
    newErrors.teamId = validateRequiredDropdown(formData.teamId)
      ? ""
      : "Project Team is required";
    newErrors.sourceId = validateRequiredDropdown(formData.sourceId)
      ? ""
      : "Source is required";
    // newErrors.selectedColumns = validateRequired(selectedColumns)
    //   ? ""
    //   : "Associated Data Columns is required";
    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value?.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
    } else {
      setErrors({
        ...errors,
        [name]: `${
          name === "projectTeam"
            ? "Project Team"
            : name === "sourceId"
            ? "Data Source"
            : "This Field"
        } is required`,
      });
    }
  };
  const handleTeam = (event) => {
    const selectedTeamId = event.target.value;
    const selectedTeam = teamsList.find((team) => team.id === selectedTeamId); // Find the selected team based on ID

    if (selectedTeam) {
      setFormData({
        ...formData,
        teamId: selectedTeam.id, // Update team ID
        projectName: selectedTeam.teamName, // Update project name
      });
    }
  };

  useEffect(() => {
    if (operationId && progress !== 100) {
      const interval = setInterval(async () => {
        dispatch(pollProgress(operationId)); // Poll for progress updates
      }, 1000); // Poll every second

      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [operationId, progress]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    dispatch(startOperation);
   // setFormData(initialState); // Start the operation and get the operation ID
  };

  useEffect(() => {
    if (operationId !== undefined) {
      const payload = {
        ...formData,
        status: "da973bc9-b30e-447b-aa42-14dc7e0cdb01",
        operationId: operationId,
        columns: mappedColumns.map((col) => ({
          colId: col.colId,
          colName: columnsList.find((colData) => colData.id === col.colId)
            ?.headerId,
        })),
      };
      
      dispatch(AddProject(payload)); // Dispatch the AddProject action with the payload
    }
  }, [operationId]);

  useEffect(() => {
    if (progress >= 100) {
      dispatch(setProgress(undefined));
      dispatch(OperationSuccess(undefined));
    }
  }, [progress]);
  useEffect(() => {
    if (
      loading.value == "projectList" &&
      !loading.loading &&
      progress === 100
    ) {
      props.closeFunction();
      setFormData(initialState);
      setSelectedColumns([]);
      dispatch(setProgress(0));
    }
  }, [loading, progress]);
  useEffect(() => {
    if (!props.show) {
      setFormData(initialState);
      setSelectedColumns([])
    //   setMappedColumns([])
      setErrors({});
    }
  }, [props.show]);

  // Filter out already mapped columns
  const availableColumns = columnsList?.filter(
    (col) => !mappedColumns?.some((mapped) => mapped.colId === col.id)
  );

  const associatedDataOption = availableColumns?.map((col) => ({
    value: col.id, // Using col.id for the value
    label: col.headerValue, // Assuming headerValue is a property of col
  }));

  return (
    <Offcanvas
      show={props.show}
      onHide={props.closeFunction}
      placement="end"
      className="w-40"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="fs-16 text-dark">
          Create New Project
        </Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body>
        <Row className="mb-4">
          <Col md={12}>
            <div>
              <Form.Label>
                Project Team<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                className="wt-400"
                value={formData.teamId}
                onChange={handleTeam}
                name="projectTeam"
              >
                <option disabled value={Environment.defaultValue}>
                  Select Team
                </option>
                {filteredList?.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.teamName}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-danger">{errors.teamId}</Form.Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-4">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={4}
                placeholder="Enter Description"
                name="projectDescription"
                value={formData.projectDescription}
                onChange={handleInputChange}
              />
            </div>
          </Col>
        </Row>
        {/* <Row className="mb-4">
                    <Col md={12}>
                        <div>
                            <Form.Label>Employee Code Column</Form.Label>
                            <Form.Select className="wt-400" name="empId" value={formData.empId} onChange={handleInputChange}>
                                <option >Select Employee Code Field</option>
                                {columnsList?.map((header) => (
                                    <option key={header.id} value={header.headerId}>
                                        {header.headerValue}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Col>
                </Row> */}
        <Row className="mb-4">
          <Col md={12}>
            <div>
              <Form.Label>
                Data Source: <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                className="wt-400"
                name="sourceId"
                value={formData.sourceId}
                onChange={handleInputChange}
              >
                <option disabled value={Environment.defaultValue}>
                  Select Data Source
                </option>
                {datasourceList?.map((source) => (
                  <option key={source.id} value={source.id}>
                    {source.datasourceName}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-danger">{errors.sourceId}</Form.Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-4">
              <Form.Label>Associated Data Columns</Form.Label>
              <div className="d-flex gap-2 mb-4">
                <Select
                  className="wt-400"
                  options={associatedDataOption} // Dropdown options
                  value={selectedColumns} // Bind to selected teams state
                  onChange={handleColChange} // Update state on selection change
                  isMulti={true} // Enable multi-select
                  isSearchable={true} // Enables searching in the dropdown
                  placeholder="Select Columns" // Updated placeholder
                />
                <Button type="button" variant="dark" onClick={handleAddColumns}>
                  Include
                </Button>
              </div>
              <div>
                <h6 className="fs-14 mb-2">Defaults fields</h6>
                <div className="d-flex gap-2 align-items-center flex-wrap mb-3">
                  {mappedColumns
                    ?.filter(
                      (item) =>
                        item.status === "da973bc9-b30e-447b-aa42-14dc7e0cdb01"
                    )
                    .map((item, index) => (
                      <Badge className="fs-14" bg="secondary" pill key={index}>
                        <span>{item.colName}</span>
                      </Badge>
                    ))}
                </div>

                <div className="d-flex gap-2 align-items-center flex-wrap">
                  {mappedColumns?.length === 0 ? (
                    <h6 className="fs-14 text-center">No columns mapped</h6>
                  ) : (
                    mappedColumns
                      ?.filter(
                        (item) =>
                          item.status !== "da973bc9-b30e-447b-aa42-14dc7e0cdb01"
                      )
                      .map((item, index) => (
                        <Badge bg="primary" className="fs-14" key={index} pill>
                          <span>{item.colName}</span>
                          <Link
                            className="text-white align-middle ms-1"
                            onClick={() => removeCol(item)}
                          >
                            <i className="ri-close-circle-line"></i>
                          </Link>
                        </Badge>
                      ))
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Offcanvas.Body>

      <div className="offcanvas-footer justify-content-start">
        <Button
          type="submit"
          variant="primary"
          className="fs-14 me-2 d-flex align-items-center"
          onClick={handleSubmit}
          disabled={loading.loading && loading.value === "projectList"} // Disable button when loading
        >
          <i className="ri-add-line fs-18 lh-1 align-middle"></i>
          {loading.loading && loading?.value === "projectList" ? (
            <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
          ) : (
            <span className="align-middle">Create Project</span>
          )}
        </Button>
        {progress > 0 && <ProgressBar progress={progress} />}
      </div>
    </Offcanvas>
  );
}

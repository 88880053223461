import errorMiddleware from './errorMiddleware'
import teamsMiddleware from './teamsMiddleware'
import userMiddleware from './userMiddleware'
import inventoryMiddleware from './inventoryMiddleware'
import projectMiddleware from './projectMiddleware'
import auditMiddleware from './auditMiddlware'
import loaderMiddleware from './loaderMiddleware'

export default [
    ...teamsMiddleware,
    ...userMiddleware,
    ...errorMiddleware,
    ...inventoryMiddleware,
    ...projectMiddleware,
    ...auditMiddleware,
    ...loaderMiddleware
]

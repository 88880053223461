import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getMapProjectList } from "../../../application/selector/indexSelector";

export default function MappedProject() {
    // HANDLE DELETE PROJECT
    const [data, setData] = useState([]);
    const MappedProjectsList = useSelector(getMapProjectList)

    const handleDelete = (index) => {
        SweetAlertService.showAlert(
            "Project Name",
            "Are you sure you want to delete this Project Name?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    ToastifyService.success(`Project Name Deleted Successfully`);
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };
    const associatedDataOption = [

    ]
    
    return (
        <React.Fragment>
            <div className="mb-3 d-flex justify-content-end gap-3 position-relative z-index-10">
                <div className="d-flex gap-2">
                    <Select
                        className="wt-300"
                        options={associatedDataOption} // Dropdown options
                        placeholder="Select Mapped Project" // Placeholder text
                    />

                    <Button type="button" variant="dark">Include</Button>
                </div>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Project Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {MappedProjectsList?.map((data, index) => (
                        <tr key={data.id || index}>
                            <td>{data.projectName}</td>
                            <td>
                                <Button variant="outline-danger" onClick={() => handleDelete(true)} className="btn-icon"><i className="ri-delete-bin-line"></i></Button>
                            </td>
                        </tr>
                    ))}


                </tbody>
            </Table>
        </React.Fragment>
    )
};
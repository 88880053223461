import React, { useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../../../hooks/useAuth';
import { getPointList } from '../../../../application/selector/indexSelector';
import SweetAlertService from '../../../_common/SweetAlertService';
import ToastifyService from '../../../_common/ToastifyService';
import { format } from 'date-fns';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CreatePointsMaster from './createPointsMaster';

function POintsMaster() {
    const [filesInfo, setFilesInfo] = useState([]);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const { setAuth, auth } = useAuth();
    const PointList = useSelector(getPointList)
 
    const handleDelete = (index) => {
        SweetAlertService.showAlert(
            "User",
            "Are you sure you want to delete this User?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    ToastifyService.success(`User Deleted Successfully`);
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };
    const columns = [
        {
            name: "Name",
            selector: (row) => (
                <div>
                    <h6 className="mb-0 fw-semibold">
                        <Link>{row.pointName}</Link>
                    </h6>
                    <span className="fs-xs text-secondary">{row.description}</span>

                </div>
            ),
        },
        {
            name: "Value",
            selector: (row) => (
                <div>
                    {row.pointValue}

                </div>
            ),
        },
        {
            name: "Modified On",
            selector: (row) => format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
        },
    //     {
    //         name: "Action",
    //         selector: (row) => (
    //             <div className="d-flex justify-content-end">
    //                 <Button
    //                     variant="primary"
    //                     className="btn-icon me-2"
    //                     //onClick={() => handleEdit(true, row)}
    //                 >
    //                     <i className="ri-pencil-line"></i>
    //                 </Button>
    //                 <Button
    //                     variant="outline-danger"
    //                     className="btn-icon"
    //                     onClick={() => handleDelete(row)}
    //                 >
    //                     <i className="ri-delete-bin-line"></i>
    //                 </Button>
    //             </div>
    //         ),
    //         width: "200px",
    //     },
    ];
    const [showCreateModal, setShowCreateModal] = useState(false);
    const handleCreateModal = (state) => {
        setShowCreateModal(state);
    }
    return (
        <React.Fragment>
             <CreatePointsMaster show={showCreateModal}  closeFunction={handleCreateModal} />
            
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16">Points Master</h4>
                </div>
                <div className="d-flex gap-2">
                    <div>
                        <Button variant="primary" onClick={handleCreateModal}>
                            +  Add Points 
                        </Button>
                    </div>
                </div>
            </div>

            <div>
                {error && <p className="text-center" style={{ color: "red" }}>{error}</p>}
                {PointList?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={PointList}
                        pagination
                        highlightOnHover
                    />
                ) : (
                    !error && <p className="text-center">No records to display</p>
                )}
            </div>
        </React.Fragment>
    )
}

export default POintsMaster
import React, { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import { Button, Col, Form, Row } from "react-bootstrap";
import SweetAlertService from "../../../_common/SweetAlertService";
import ToastifyService from "../../../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import { DeleteError, ErrorCategoryList, ErrorList, ErrorUpload } from "../../../../application/action/errorAction";
import useAuth from "../../../../hooks/useAuth";
import { getErrorList } from "../../../../application/selector/indexSelector";
import { format } from "date-fns";
import CreateErrorCategories from "../ErrorCategories/create-error-categories";
import UpdateErrorType from "../ErrorCategories/create-error-categories";
import ErrorType_Template from '../../../../assets/templates/ErrorType_Template.xlsx'


export default function ErrorType() {
    const [filesInfo, setFilesInfo] = useState([]);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const fileInputRef = useRef(null);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [editRowData, setEditRowData] = useState({});
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const ErrorsList = useSelector(getErrorList);

    // HANDLE FILE UPLOAD FUNCTION
    const handleFileUpload = (event) => {
        const files = event.target.files;
        if (files.length === 0) return; // No file selected

        const formData = new FormData();
        formData.append("CreatedBy", auth.id);
        formData.append("File", files[0]); // Use the first file

        dispatch(ErrorUpload(formData)); // Assuming `dispatch` is correctly set up

        setError(null);
        setFilesInfo([]);
    };

    // HANDLE BULK UPLOAD FUNCTION
    const handleBulkUpload = () => {
        fileInputRef.current.click();
    };

    // HANDLE EDIT FUNCTION
    const handleEdit = (state, data) => {
        setEditRowData(data);
        setShowUpdateUser(state);
    };

    // HANDLE SAVE EDIT FUNCTION
    const handleSaveEdit = (updatedData) => {
        const newData = [...data];
        newData[editRowData.index] = updatedData;
        setData(newData);
    };

    // HANDLE DELETE FUNCTION
    // const handleDelete = ( id) => {
    //     SweetAlertService.showAlert(
    //         "Error Type",
    //         "Are you sure you want to delete this Error Typ?",
    //         "warning"
    //     )
    //         .then((result) => {
    //             if (result.isConfirmed) {
    //                 dispatch(DeleteError(id))
    //                 ToastifyService.success(`Error Typ Deleted Successfully`);

    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error:", error);
    //             ToastifyService.error(`Something went wrong`);
    //         });
    // };

    const columns = [
        {
            name: "Error Type",
            selector: (row) => (
                <div>
                    {/* <h6 className="mb-0 fw-semibold"> */}
                    {row.errorName}
                    {/* <Link>{row.errorName}</Link> */}
                    {/* </h6> */}
                    {/* <span className="fs-xs text-secondary">{row.emp_username}</span> */}
                </div>
            ),
        },
        {
            name: "Modified On",
            selector: (row) => format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    {/* <Button
                        variant="primary"
                        className="btn-icon me-2"
                        onClick={() => handleEdit(true, row)}
                    >
                        <i className="ri-pencil-line"></i>
                    </Button> */}
                    {/* <Button
                        variant="outline-danger"
                        className="btn-icon"
                        onClick={() => handleDelete(row.id)}
                    >
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                     */}
                    <Form.Check
                        type="switch"
                        id={`custom-switch-${row.id}`}
                        checked={row.status === '5e03965f-c410-46b3-8713-b5058348ab02'} // Check if status matches
                        className="mb-0"
                        onChange={(e) => handleStatusChange(row.id, e.target.checked)} // Handle status change
                        custom
                    />
                </div>
            ),
            width: "200px",
        },
    ];
    const handleStatusChange = (id, isChecked) => {
        const newStatus = isChecked ? '5e03965f-c410-46b3-8713-b5058348ab02' : '5e03965f-c410-46b3-8713-b5058348ab03';

        // Update the status in the backend
        dispatch(DeleteError({ id, status: newStatus }))
            .then(() => {
                // Update the status in the local state
                const updatedData = data.map((item) =>
                    item.id === id ? { ...item, status: newStatus } : item
                );
                setData(updatedData);

            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Failed to update status`);
            });
    };
    const [showErrorCategories, setShowErrorCategories] = useState(false);
    const handleErrorCategories = (state, data) => {
        setShowErrorCategories(state);
    }
    const [searchTerm, setSearchTerm] = useState('');
    const filteredErrorsList = ErrorsList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    return (
        <React.Fragment>
            <UpdateErrorType show={showUpdateUser} closeFunction={() => setShowUpdateUser(false)} rowData={editRowData} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    {/* <h4 className="fs-16">Error Type</h4> */}
                </div>
                <div className="d-flex gap-2">
                    <div className="custom-drop-down z-index-2 wt-300">
                        {/* <span className=" d-flex dropdown-icon bg-white"><i className="ri-search-line"></i></span> */}
                        <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    {/* <div>
                        <a
                            href={ErrorType_Template} 
                            download="ErrorType_Template.xlsx"
                        >
                            <Button variant="outline-primary">
                                {" "}
                                <i className="ri-download-line fs-16 lh-1"></i> Download
                                Template
                            </Button>
                        </a>

                    </div> */}
                    <div>
                        <Form.Control
                            type="file"
                            ref={fileInputRef} style={{ display: "none" }} accept=".xls,.xlsx,.csv" onChange={handleFileUpload}
                        />
                        <Button variant="primary" onClick={handleBulkUpload}>
                            <i className="ri-file-excel-2-line align-middle"></i> Upload Error Type
                        </Button>
                    </div>
                </div>
            </div>

            <div>
                {error && <p className="text-center" style={{ color: "red" }}>{error}</p>}
                {filteredErrorsList?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={filteredErrorsList}
                        pagination
                        highlightOnHover
                    />
                ) : (
                    !error && <p className="text-center">No records to display</p>
                )}
            </div>
        </React.Fragment>
    )
}
import React, { useState, useEffect } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import { AddUser, UpdateUserData } from "../../../../application/action/userAction";
import {
  getRolesList,
  getSupervisorList,
  getTeamsList,
} from "../../../../application/selector/indexSelector";
import {
  validateRequired,
  validateRequiredDropdown,
  validateRequiredNumberDropdown,
  restrictSpace,
  validateEmail,
  restrictConsecutiveSpace,
} from "../../../../infrastructure/core/validationUtils";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import Environment from "../../../../infrastructure/core/Environment";
const passwordRegex =
  /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&]).{8,20})/;

export default function UpdateUser(props) {
  const RolesList = useSelector(getRolesList);
  const TeamsList = useSelector(getTeamsList);
  const SupervisorList = useSelector(getSupervisorList);
  const dispatch = useDispatch();
  const { auth } = useAuth();
  console.log(props);
  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };
  const initialState = {
    id: Environment.defaultValue,
    fullName: "",
    username: "",
    roleId: 0,
    email: "",
    password: "",
    empCode: "",
    supervisorId: Environment.defaultValue,
    teamId: Environment.defaultValue,
    createdBy: auth.id,
    modifiedBy: auth.id,
    isResetPassword: false
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    
    setFormData({
      ...formData,
      id: props?.rowData?.empId,
      fullName: props?.rowData?.emp_fullname,
      email: props?.rowData?.email,
      username: props?.rowData?.emp_username,
      empCode: props?.rowData?.empId,
      roleId: props?.rowData?.roleId,
      supervisorId: props?.rowData?.supervisorId,
      teamId: props?.rowData?.teamId,
      isResetPassword: false,
    });
  }, [props?.rowData]);

  const [errors, setErrors] = useState({});
  const validateInput = () => {
    const newErrors = {};
    // Validate Charge Name
    newErrors.fullName = validateRequired(formData.fullName)
      ? ""
      : "Full Name is required";
    newErrors.empCode = validateRequired(formData.empCode)
      ? ""
      : "Emp Code is required";
    newErrors.roleId = validateRequiredNumberDropdown(formData.roleId)
      ? ""
      : "Role is required";
    newErrors.teamId = validateRequiredDropdown(formData.teamId)
      ? ""
      : "Team is required";
    // newErrors.supervisorId = validateRequiredDropdown(formData.supervisorId)
    //   ? ""
    //   : "Supervisor is required";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value?.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
      // Email format validation
      // if (name === "email") {
      //   const isValidEmail = validateEmail(value);
      //   if (!isValidEmail) {
      //     setErrors({
      //       ...errors,
      //       [name]: "Invalid email format",
      //     });
      //     return;
      //   } else {
      //     checkDuplicateEmail(name, value);
      //   }
      // }
    } else {
      setErrors({
        ...errors,
        [name]: `${name === "fullName"
            ? "Full Name"
            : name === "email"
              ? "Email"
              : name === "username"
                ? "Username"
                : name === "empCode"
                  ? "Emp Code"
                  : name === "roleId"
                    ? "Role"
                    : name === "teamId"
                      ? "Team"
                      : name === "supervisorId"
                        ? "Supervisor"
                        : "This Field"
          } is required`,
      });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    const isValid = validateInput();
    if (!validatePassword(formData.password)) {
      setErrors({
        ...errors, password:
          "Password must be at least 8 characters long, contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
      });
      return;
    }
    if (!isValid) {
      return;
    }
    
    dispatch(UpdateUserData(formData));
    setFormData(initialState);
    props.closeFunction();
  };
  const handleToggleResetPassword = () => {
    // setErrors({});
    setFormData({
      ...formData,
      password: "",
      confirmPassword: "",
      isResetPassword: !formData.isResetPassword,
    });
  };
  return (
    <Offcanvas
      show={props.show}
      onHide={props.closeFunction}
      placement="end"
      className="w-40"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="fs-16 text-dark">
          Update User
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>
                Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                onKeyDown={restrictConsecutiveSpace}
              />
              <Form.Text className="text-danger">{errors.fullName}</Form.Text>
            </div>
          </Col>
          <Col>
            <div className="mb-4">
              <Form.Label>
                Email<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>
                AMD Username<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter AMD Username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                disabled
              />
            </div>
          </Col>
          {formData.roleId == 6 && (
            <Col>
              <div className="mb-4">
                <Form.Label>
                  Supervisor<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="supervisorId"
                  value={formData.supervisorId}
                  onChange={handleInputChange}
                >
                  <option value={Environment.defaultValue} disabled>
                    Choose Supervisor
                  </option>
                  {SupervisorList?.map((sup) => (
                    <option key={sup.empId} value={sup.empId}>
                      {sup.emp_fullname}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>
                Role<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="roleId"
                value={formData.roleId}
                onChange={handleInputChange}
                disabled
              >
                <option disabled>Choose Role</option>
                {RolesList?.map((role) => (
                  <option key={role.id} value={Number(role.id)}>
                    {role.role}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-danger">{errors.roleId}</Form.Text>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-4">
              <Form.Label>
                Team Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="teamId"
                value={formData.teamId}
                onChange={handleInputChange}
              >
                <option disabled>Choose Team Name</option>
                {TeamsList?.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.teamName}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-danger">{errors.teamId}</Form.Text>
            </div>
          </Col>
        </Row>

        <Row>

          {formData.roleId !== 6 && (
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Reset Password</Form.Label>
                <div className="d-flex align-items-center">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={formData.isResetPassword}
                      onChange={handleToggleResetPassword}
                      style={{ width: "3em", height: "1.5em" }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          )}
          {/* {formData.roleId == 6 && (
            <Col>
              <div className="mb-4">
                <Form.Label>
                  Supervisor<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="supervisorId"
                  value={formData.supervisorId}
                  onChange={handleInputChange}
                >
                  <option value={Environment.defaultValue} disabled>
                    Choose Supervisor
                  </option>
                  {SupervisorList?.map((sup) => (
                    <option key={sup.empId} value={sup.empId}>
                      {sup.emp_fullname}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.supervisorId}
                </Form.Text>
              </div>
            </Col>
          )} */}
        </Row>

        {formData.isResetPassword && (
          <>
            <Row>
              <Col xl={6}>
                <div className="mb-4">
                  <Form.Label>
                    Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter New Password"
                    name="password"
                    onChange={handleInputChange}
                    value={formData.password}
                    autoComplete="off"
                  />
                </div>
              </Col>
            </Row>

            {/* <Row>
              <Col xl={6}>
                <div className="mb-4">
                  <Form.Label>
                    Confirm Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm New Password"
                    name="confirmPassword"
                    onChange={handleInputChange}
                    value={formData.confirmPassword}
                    autoComplete="off"
                  />
                </div>
              </Col>
            </Row> */}
            {errors.password && (
              <span className="text-danger fs-11">
                {errors.password}
              </span>
            )}
          </>
        )}
      </Offcanvas.Body>

      <div className="offcanvas-footer justify-content-start">
        <Button
          type="submit"
          variant="primary"
          className="fs-14 me-2 d-flex align-items-center"
          onClick={handleSubmit}
        >
          <i className="ri-add-line fs-18 lh-1 align-middle"></i>
          <span className="align-middle">Update User</span>
        </Button>
      </div>
    </Offcanvas>
  );
}

import { Navigate } from "react-router-dom";
import * as Loading from "../action/loaderAction";
import { GET_TEAMS_LIST, TeamsListSuccess, UPLOAD_TEAM } from "../action/TeamsAction";
import ToastifyService from "../../view/_common/ToastifyService";


const TeamsList = ({ api }) => ({ dispatch }) => (next) => async (action) => {
    if (action.type === GET_TEAMS_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "teamsList" }));
        const teamsList = await api.teamsAPI.TeamsList(action.payload);
        
        dispatch(TeamsListSuccess(teamsList));
        dispatch(Loading.setLoading({ loading: false, value: "teamsList" }));
      } catch (error) {
ToastifyService.error("Something Went wrong! ");
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UploadTeams =
  ({ api }) =>
    ({ dispatch }) =>
      (next) =>
        async (action) => {
          if (action.type === UPLOAD_TEAM) {
            try {
              dispatch(Loading.setLoading({ loading: true, value: "userDetails" }));
              const teamslist = await api.teamsAPI.UploadTeams(action.payload);
              dispatch(TeamsListSuccess(teamslist));
              dispatch(Loading.setLoading({ loading: false, value: "userDetails" }));
            } catch (error) {
              ToastifyService.error("Something Went wrong! ");
              console.log(error)
              if (error.response?.data === "Invalid access token or refresh token") {
                localStorage.removeItem("Token");
                <Navigate to="/account/login" />;
              }
            }
          }
          next(action);
        };

export default [
    TeamsList, UploadTeams

  ]
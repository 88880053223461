import React, { useEffect, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import UpdateUser from '../pages/update-user';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import ToastifyService from '../../../_common/ToastifyService';
import { DeleteUser, GetAllSupervisor } from '../../../../application/action/userAction';
import SweetAlertService from '../../../_common/SweetAlertService';
import { useDispatch, useSelector } from 'react-redux';
import { getSupervisorList, getUserList } from '../../../../application/selector/indexSelector';

function AdminManage() {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const UsersList = useSelector(getUserList);
  const AdminList  = UsersList?.filter(data=> data.roleId ===1)
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [editRowData, setEditRowData] = useState({});
  useEffect(() => {
    dispatch(GetAllSupervisor(3));
  }, []);
  // HANDLE EDIT FUNCTION
  const handleEdit = (state, row) => {
   
    const newState = {
      empId: row.id,
      emp_fullname: row.fullName,
      emp_username: row.userName,
      roleId: 1,
      email: row.email,
      password: "Abteam@2024",
      empCode: "",
      isResetPassword: false
    }
    setEditRowData(newState);
    setShowUpdateUser(state);
  };
  // HANDLE DELETE FUNCTION
  const [data, setData] = useState([]);
  const handleDelete = (row) => {
    SweetAlertService.showAlert(
      "Supervisor",
      "Are you sure you want to delete this Supervisor?",
      "warning"
    )
      .then((result) => {
        if (result.isConfirmed) {

          dispatch(DeleteUser(row.empId))
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastifyService.error(`Something went wrong`);
      });
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => <div>{row.id}</div>,
      width: "50px",
    },
    {
      name: "Master Name",
      selector: (row) => (
        <div>
          <h6 className="mb-0 fw-semibold">
            <Link>{row.fullName}</Link>
          </h6>
          <span className="fs-xs text-secondary">{row.email}</span>
        </div>
      ),
    },
    {
      name: "UserName",
      selector: (row) => row.userName,
    },
    // {
    //   name: "Team",
    //   selector: (row) => row.teamName,
    // },
    {
      name: "Modified On",
      selector: (row) =>
        format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleEdit(true, row)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          {/* <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => handleDelete(true)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button> */}
        </div>
      ),
      width: "200px",
    },
  ];
  const [searchTerm, setSearchTerm] = useState('');
  const filteredSupervisorList = AdminList?.filter((item) =>
    Object.values(item).some((value) =>
      value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  return (
    <React.Fragment>
      <UpdateUser
        show={showUpdateUser}
        closeFunction={() => setShowUpdateUser(false)}
        rowData={editRowData}
        columns={columns}
      />
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="fs-16">Admin</h4>
        </div>
        <div>
          <div className="custom-drop-down z-index-2 wt-300">
            {/* <span className=" d-flex dropdown-icon bg-white"><i className="ri-search-line"></i></span> */}
            <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
        </div>
      </div>
      <Card className="card-one">
        <Card.Body>
          {/* {error && <p className="text-center" style={{ color: "red" }}>{error}</p>} */}
          {filteredSupervisorList?.length > 0 ? (
            <DataTable
              columns={columns}
              data={filteredSupervisorList}
              pagination
              highlightOnHover
            />
          ) : (
            !error && <p className="text-center">No records to display</p>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

export default AdminManage